import gql from "graphql-tag";

export default gql`
  fragment FlowPlatformEcommerceProductFragment on platform_ecommerce_product {
    platform_id
    name
    image_url
    platform_variants(limit: 200) {
      platform_id
      name
      price
      currency
      platform_variant_options {
        key
        value
      }
    }
    platform_plan_ecommerce_products(
      where: { primary: { _eq: true } }
      limit: 1
    ) {
      discount_amount
      discount_type
    }
  }
`;
