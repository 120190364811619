import gql from "graphql-tag";

import FlowOfferModifySubscriptionOptionFragment from "./FlowOfferModifySubscriptionOptionFragment";
import FlowOfferPauseSubscriptionOptionFragment from "./FlowOfferPauseSubscriptionOptionFragment";
import TranslationFragment from "./TranslationFragment";

export default gql`
  fragment FlowOfferFragment on offer {
    id
    token
    type
    name
    show_banner
    image_url
    goal
    style
    created_at
    updated_at
    headline_translation {
      ...TranslationFragment
    }
    content_translation {
      ...TranslationFragment
    }
    button_text_translation {
      ...TranslationFragment
    }
    offer_coupon {
      type
      amount_off
      duration
      duration_interval
      duration_count
      months
    }
    offer_change_plan {
      platform_plan_id
      prorate
      change_at
    }
    offer_modify_subscription {
      prorate
      modify_at
      offer_modify_subscription_options {
        ...FlowOfferModifySubscriptionOptionFragment
      }
    }
    offer_trial_extension {
      days
    }
    offer_pause_subscription {
      offer_pause_subscription_options(order_by: { position: asc }) {
        ...FlowOfferPauseSubscriptionOptionFragment
      }
    }
    offer_upgrade {
      platform_plan_id
      change_at
    }
    offer_downgrade {
      platform_plan_id
      change_at
    }
    offer_product_swap {
      specific_products
      offer_product_swap_platform_ecommerce_products {
        platform_ecommerce_product_id
        platform_ecommerce_product {
          platform_id
          name
          image_url
          platform_plan_ecommerce_products(
            where: { primary: { _eq: true } }
            limit: 1
          ) {
            discount_amount
            discount_type
          }
        }
        offer_product_swap_platform_ecommerce_product_platform_variants {
          platform_variant {
            platform_id
            name
            price
            currency
            platform_variant_options {
              key
              value
            }
          }
        }
      }
    }
    offer_reschedule_order {
      skip
      reschedule
      skip_button_text_translation {
        ...TranslationFragment
      }
      reschedule_button_text_translation {
        ...TranslationFragment
      }
    }
    offer_custom {
      link_href
      link_text
    }
    offer_timer {
      offer_timer_mode
      start_time
      end_time
      duration
      show_timer
    }
    offer_autopilot {
      offer_id
    }
  }
  ${TranslationFragment}
  ${FlowOfferModifySubscriptionOptionFragment}
  ${FlowOfferPauseSubscriptionOptionFragment}
`;
