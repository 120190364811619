import { UnreachableCaseError } from "ts-essentials";

import {
  coupon_duration_enum,
  coupon_duration_interval_enum,
  FlowOfferFragment,
  FlowOfferPauseSubscriptionOptionFragment,
  language_enum,
  offer_change_plan_change_at_enum,
  offer_coupon_type_enum,
  offer_goal_enum,
  offer_modify_subscription_modify_at_enum,
  offer_pause_subscription_interval_enum,
  offer_pause_subscription_option_type_enum,
  offer_style_enum,
  offer_timer_mode_enum,
  offer_type_enum,
  translation_value_format_enum,
} from "../../../__generated__/graphql";
import {
  ChangePlanOfferFormValues,
  CouponOfferFormValues,
  CustomOfferFormValues,
  DowngradeOfferFormValues,
  ModifySubscriptionOfferFormValues,
  OfferFormValues,
  PauseSubscriptionOfferFormValues,
  ProductSwapOfferFormValues,
  RescheduleOrderOfferFormValues,
  TrialExtensionOfferFormValues,
  UpgradeOfferFormValues,
} from "../forms/types";

const mapPreviewOffer = (
  formValues: OfferFormValues,
  goal: offer_goal_enum
): FlowOfferFragment => {
  const now = new Date().toISOString();
  const content = formValues.content
    ? typeof formValues.content === "object"
      ? formValues.content
      : JSON.parse(formValues.content)
    : undefined;

  let values = formValues;

  const offerType = formValues.type as offer_type_enum;

  switch (offerType) {
    case offer_type_enum.change_plan:
      values = formValues as ChangePlanOfferFormValues;

      return {
        __typename: "offer",
        id: 1,
        token: "offer",
        type: offer_type_enum.change_plan,
        name: values.name,
        show_banner: values.showBanner,
        image_url: values.image,
        created_at: now,
        updated_at: now,
        headline_translation: {
          __typename: "translation",
          id: 1,
          translation_values: [
            {
              __typename: "translation_value",
              translation_id: 1,
              language: language_enum.en_us,
              value: values.headline,
              format: translation_value_format_enum.text,
            },
          ],
        },
        content_translation: {
          __typename: "translation",
          id: 2,
          translation_values: [
            {
              __typename: "translation_value",
              translation_id: 2,
              language: language_enum.en_us,
              value: content,
              format: values.contentFormat,
            },
          ],
        },
        button_text_translation: {
          __typename: "translation",
          id: 3,
          translation_values: [
            {
              __typename: "translation_value",
              translation_id: 3,
              language: language_enum.en_us,
              value: values.buttonText,
              format: translation_value_format_enum.text,
            },
          ],
        },
        offer_coupon: null,
        offer_change_plan: {
          __typename: "offer_change_plan",
          platform_plan_id: values.planId,
          prorate: values.prorate,
          change_at: values.changeAt as offer_change_plan_change_at_enum,
        },
        offer_trial_extension: null,
        offer_pause_subscription: null,
        offer_custom: null,
        offer_timer: {
          __typename: "offer_timer",
          offer_timer_mode: values.timerMode as offer_timer_mode_enum,
          start_time: values.startTime
            ? values.startTime.toZonedDateTime("UTC").toInstant().toString()
            : null,
          end_time: values.endTime
            ? values.endTime.toZonedDateTime("UTC").toInstant().toString()
            : null,
          duration: values.offerLength,
          show_timer: values.countdownTimer,
        },
        goal,
        style: (values.style as offer_style_enum) || offer_style_enum.modal,
      };

    case offer_type_enum.coupon:
      values = formValues as CouponOfferFormValues;

      return {
        __typename: "offer",
        id: 1,
        token: "offer",
        type: offer_type_enum.coupon,
        name: values.name,
        show_banner: values.showBanner,
        image_url: values.image,
        created_at: now,
        updated_at: now,
        headline_translation: {
          __typename: "translation",
          id: 1,
          translation_values: [
            {
              __typename: "translation_value",
              translation_id: 1,
              language: language_enum.en_us,
              value: values.headline,
              format: translation_value_format_enum.text,
            },
          ],
        },
        content_translation: {
          __typename: "translation",
          id: 2,
          translation_values: [
            {
              __typename: "translation_value",
              translation_id: 2,
              language: language_enum.en_us,
              value: content,
              format: values.contentFormat,
            },
          ],
        },
        button_text_translation: {
          __typename: "translation",
          id: 3,
          translation_values: [
            {
              __typename: "translation_value",
              translation_id: 3,
              language: language_enum.en_us,
              value: values.buttonText,
              format: translation_value_format_enum.text,
            },
          ],
        },
        offer_coupon: {
          __typename: "offer_coupon",
          type: values.couponType as offer_coupon_type_enum,
          amount_off: values.amountOff?.toString() || "0",
          duration: values.duration as coupon_duration_enum,
          duration_interval: values.durationInterval
            ? (values.durationInterval as coupon_duration_interval_enum)
            : null,
          duration_count: values.durationCount,
          months: values.months,
        },
        offer_change_plan: null,
        offer_trial_extension: null,
        offer_pause_subscription: null,
        offer_custom: null,
        offer_timer: {
          __typename: "offer_timer",
          offer_timer_mode: values.timerMode as offer_timer_mode_enum,
          start_time: values.startTime ? values.startTime.toString() : null,
          end_time: values.endTime ? values.endTime.toString() : null,
          duration: values.offerLength,
          show_timer: values.countdownTimer,
        },
        goal,
        style: (values.style as offer_style_enum) || offer_style_enum.modal,
        offer_autopilot: {
          __typename: "offer_autopilot",
          offer_id: 1,
        },
      };

    case offer_type_enum.custom:
      values = formValues as CustomOfferFormValues;

      return {
        __typename: "offer",
        id: 1,
        token: "offer",
        type: offer_type_enum.custom,
        name: values.name,
        show_banner: values.showBanner,
        image_url: values.image,
        created_at: now,
        updated_at: now,
        headline_translation: {
          __typename: "translation",
          id: 1,
          translation_values: [
            {
              __typename: "translation_value",
              translation_id: 1,
              language: language_enum.en_us,
              value: values.headline,
              format: translation_value_format_enum.text,
            },
          ],
        },
        content_translation: {
          __typename: "translation",
          id: 2,
          translation_values: [
            {
              __typename: "translation_value",
              translation_id: 2,
              language: language_enum.en_us,
              value: content,
              format: values.contentFormat,
            },
          ],
        },
        button_text_translation: {
          __typename: "translation",
          id: 3,
          translation_values: [
            {
              __typename: "translation_value",
              translation_id: 3,
              language: language_enum.en_us,
              value: values.buttonText,
              format: translation_value_format_enum.text,
            },
          ],
        },
        offer_coupon: null,
        offer_change_plan: null,
        offer_trial_extension: null,
        offer_pause_subscription: null,
        offer_custom: {
          __typename: "offer_custom",
          link_href: "",
          link_text: "",
        },
        offer_timer: {
          __typename: "offer_timer",
          offer_timer_mode: values.timerMode as offer_timer_mode_enum,
          start_time: values.startTime
            ? values.startTime.toZonedDateTime("UTC").toInstant().toString()
            : null,
          end_time: values.endTime
            ? values.endTime.toZonedDateTime("UTC").toInstant().toString()
            : null,
          duration: values.offerLength,
          show_timer: values.countdownTimer,
        },
        goal,
        style: (values.style as offer_style_enum) || offer_style_enum.modal,
      };

    case offer_type_enum.downgrade:
      values = formValues as DowngradeOfferFormValues;

      return {
        __typename: "offer",
        id: 1,
        token: "offer",
        type: offer_type_enum.downgrade,
        name: values.name,
        show_banner: values.showBanner,
        image_url: values.image,
        created_at: now,
        updated_at: now,
        headline_translation: {
          __typename: "translation",
          id: 1,
          translation_values: [
            {
              __typename: "translation_value",
              translation_id: 1,
              language: language_enum.en_us,
              value: values.headline,
              format: translation_value_format_enum.text,
            },
          ],
        },
        content_translation: {
          __typename: "translation",
          id: 2,
          translation_values: [
            {
              __typename: "translation_value",
              translation_id: 2,
              language: language_enum.en_us,
              value: content,
              format: values.contentFormat,
            },
          ],
        },
        button_text_translation: {
          __typename: "translation",
          id: 3,
          translation_values: [
            {
              __typename: "translation_value",
              translation_id: 3,
              language: language_enum.en_us,
              value: values.buttonText,
              format: translation_value_format_enum.text,
            },
          ],
        },
        offer_downgrade: {
          __typename: "offer_downgrade",
          platform_plan_id: values.planId,
          change_at: values.changeAt as offer_change_plan_change_at_enum,
        },
        offer_trial_extension: null,
        offer_pause_subscription: null,
        offer_custom: null,
        goal,
        style: (values.style as offer_style_enum) || offer_style_enum.modal,
      };

    case offer_type_enum.modify_subscription:
      values = formValues as ModifySubscriptionOfferFormValues;

      return {
        __typename: "offer",
        id: 1,
        token: "offer",
        type: offer_type_enum.modify_subscription,
        name: values.name,
        show_banner: values.showBanner,
        image_url: values.image,
        created_at: now,
        updated_at: now,
        headline_translation: {
          __typename: "translation",
          id: 1,
          translation_values: [
            {
              __typename: "translation_value",
              translation_id: 1,
              language: language_enum.en_us,
              value: values.headline,
              format: translation_value_format_enum.text,
            },
          ],
        },
        content_translation: {
          __typename: "translation",
          id: 2,
          translation_values: [
            {
              __typename: "translation_value",
              translation_id: 2,
              language: language_enum.en_us,
              value: content,
              format: values.contentFormat,
            },
          ],
        },
        button_text_translation: {
          __typename: "translation",
          id: 3,
          translation_values: [
            {
              __typename: "translation_value",
              translation_id: 3,
              language: language_enum.en_us,
              value: values.buttonText,
              format: translation_value_format_enum.text,
            },
          ],
        },
        offer_coupon: null,
        offer_modify_subscription: {
          __typename: "offer_modify_subscription",
          prorate: values.prorate,
          modify_at:
            values.modifyAt as offer_modify_subscription_modify_at_enum,
          offer_modify_subscription_options: [],
        },
        offer_trial_extension: null,
        offer_pause_subscription: null,
        offer_custom: null,
        goal,
        style: (values.style as offer_style_enum) || offer_style_enum.modal,
      };

    case offer_type_enum.pause_subscription:
      values = formValues as PauseSubscriptionOfferFormValues;

      const createOption = (index: 1 | 2 | 3) => {
        const option: FlowOfferPauseSubscriptionOptionFragment = {
          __typename: "offer_pause_subscription_option" as const,
          type:
            (values as PauseSubscriptionOfferFormValues)[
              `option${index}Interval`
            ] === "date"
              ? offer_pause_subscription_option_type_enum.date
              : offer_pause_subscription_option_type_enum.interval,
          interval: (values as PauseSubscriptionOfferFormValues)[
            `option${index}Interval`
          ] as offer_pause_subscription_interval_enum,
          interval_count: (values as PauseSubscriptionOfferFormValues)[
            `option${index}IntervalCount`
          ],
        };
        if (
          (values as PauseSubscriptionOfferFormValues)[`option${index}Text`]
        ) {
          option.text_translation = {
            __typename: "translation" as const,
            id: index + 5,
            translation_values: [
              {
                __typename: "translation_value" as const,
                translation_id: index + 5,
                language: language_enum.en_us,
                value: JSON.parse(
                  (values as PauseSubscriptionOfferFormValues)[
                    `option${index}Text`
                  ]!
                ),
                format: translation_value_format_enum.lexical,
              },
            ],
          };
        }

        return option;
      };

      const options: FlowOfferPauseSubscriptionOptionFragment[] = [];
      if (values.option1Present) {
        options.push(createOption(1));
      }
      if (values.option2Present) {
        options.push(createOption(2));
      }
      if (values.option3Present) {
        options.push(createOption(3));
      }

      return {
        __typename: "offer",
        id: 1,
        token: "offer",
        type: offer_type_enum.pause_subscription,
        name: values.name,
        show_banner: values.showBanner,
        image_url: values.image,
        created_at: now,
        updated_at: now,
        headline_translation: {
          __typename: "translation",
          id: 1,
          translation_values: [
            {
              __typename: "translation_value",
              translation_id: 1,
              language: language_enum.en_us,
              value: values.headline,
              format: translation_value_format_enum.text,
            },
          ],
        },
        content_translation: {
          __typename: "translation",
          id: 2,
          translation_values: [
            {
              __typename: "translation_value",
              translation_id: 2,
              language: language_enum.en_us,
              value: content,
              format: values.contentFormat,
            },
          ],
        },
        button_text_translation: {
          __typename: "translation",
          id: 3,
          translation_values: [
            {
              __typename: "translation_value",
              translation_id: 3,
              language: language_enum.en_us,
              value: values.buttonText,
              format: translation_value_format_enum.text,
            },
          ],
        },
        offer_coupon: null,
        offer_change_plan: null,
        offer_trial_extension: null,
        offer_pause_subscription: {
          __typename: "offer_pause_subscription",
          offer_pause_subscription_options: options,
        },
        offer_custom: null,
        goal,
        style: (values.style as offer_style_enum) || offer_style_enum.modal,
      };

    case offer_type_enum.product_swap:
      values = formValues as ProductSwapOfferFormValues;

      return {
        __typename: "offer",
        id: 1,
        token: "offer",
        type: offer_type_enum.product_swap,
        name: values.name,
        show_banner: values.showBanner,
        image_url: values.image,
        created_at: now,
        updated_at: now,
        headline_translation: {
          __typename: "translation",
          id: 1,
          translation_values: [
            {
              __typename: "translation_value",
              translation_id: 1,
              language: language_enum.en_us,
              value: values.headline,
              format: translation_value_format_enum.text,
            },
          ],
        },
        content_translation: {
          __typename: "translation",
          id: 2,
          translation_values: [
            {
              __typename: "translation_value",
              translation_id: 2,
              language: language_enum.en_us,
              value: content,
              format: values.contentFormat,
            },
          ],
        },
        button_text_translation: {
          __typename: "translation",
          id: 3,
          translation_values: [
            {
              __typename: "translation_value",
              translation_id: 3,
              language: language_enum.en_us,
              value: values.buttonText,
              format: translation_value_format_enum.text,
            },
          ],
        },
        offer_coupon: null,
        offer_change_plan: null,
        offer_trial_extension: null,
        offer_pause_subscription: null,
        offer_product_swap: {
          __typename: "offer_product_swap",
          specific_products: (values as ProductSwapOfferFormValues)
            .specificProducts,
          offer_product_swap_platform_ecommerce_products: (
            values as ProductSwapOfferFormValues
          ).products.map((formProduct) => ({
            __typename: "offer_product_swap_platform_ecommerce_product",
            platform_ecommerce_product_id: formProduct.productId || "",
            platform_ecommerce_product: {
              __typename: "platform_ecommerce_product",
              platform_id: formProduct.productId || "",
              name: formProduct.name,
              image_url: formProduct.imageUrl,
              platform_plan_ecommerce_products: [
                {
                  __typename: "platform_plan_ecommerce_product",
                  discount_amount: formProduct.discountAmount,
                  discount_type: formProduct.discountType,
                },
              ],
            },
            offer_product_swap_platform_ecommerce_product_platform_variants:
              formProduct.variants
                .filter((formVariant) => formVariant.value)
                .map((formVariant) => ({
                  __typename:
                    "offer_product_swap_platform_ecommerce_product_platform_variant",
                  platform_variant_id: formVariant.variantId,
                  platform_variant: {
                    __typename: "platform_variant",
                    platform_id: formVariant.variantId,
                    name: formVariant.name,
                    price: formVariant.price?.toString() || null,
                    currency: formVariant.currency,
                    platform_variant_options: formVariant.options?.map(
                      (formOption) => ({
                        __typename: "platform_variant_option",
                        key: formOption.key,
                        value: formOption.value,
                      })
                    ),
                  },
                })),
          })),
        },
        offer_custom: null,
        goal,
        style: (values.style as offer_style_enum) || offer_style_enum.modal,
      };

    case offer_type_enum.reschedule_order:
      values = values as RescheduleOrderOfferFormValues;

      return {
        __typename: "offer",
        id: 1,
        token: "offer",
        type: offer_type_enum.reschedule_order,
        name: formValues.name,
        show_banner: formValues.showBanner,
        image_url: formValues.image,
        created_at: now,
        updated_at: now,
        headline_translation: {
          __typename: "translation",
          id: 1,
          translation_values: [
            {
              __typename: "translation_value",
              translation_id: 1,
              language: language_enum.en_us,
              value: formValues.headline,
              format: translation_value_format_enum.text,
            },
          ],
        },
        content_translation: {
          __typename: "translation",
          id: 2,
          translation_values: [
            {
              __typename: "translation_value",
              translation_id: 2,
              language: language_enum.en_us,
              value: content,
              format: formValues.contentFormat,
            },
          ],
        },
        button_text_translation: {
          __typename: "translation",
          id: 3,
          translation_values: [
            {
              __typename: "translation_value",
              translation_id: 3,
              language: language_enum.en_us,
              value: formValues.buttonText,
              format: translation_value_format_enum.text,
            },
          ],
        },
        offer_coupon: null,
        offer_change_plan: null,
        offer_trial_extension: null,
        offer_pause_subscription: null,
        offer_custom: null,
        offer_reschedule_order: {
          __typename: "offer_reschedule_order",
          skip: values.skipEnabled,
          reschedule: values.rescheduleEnabled,
          skip_button_text_translation: {
            __typename: "translation",
            id: 4,
            translation_values: [
              {
                __typename: "translation_value",
                translation_id: 4,
                language: language_enum.en_us,
                value: values.skipButtonText,
                format: translation_value_format_enum.text,
              },
            ],
          },
          reschedule_button_text_translation: {
            __typename: "translation",
            id: 5,
            translation_values: [
              {
                __typename: "translation_value",
                translation_id: 5,
                language: language_enum.en_us,
                value: values.rescheduleButtonText,
                format: translation_value_format_enum.text,
              },
            ],
          },
        },
        goal: goal,
        style: (formValues.style as offer_style_enum) || offer_style_enum.modal,
      };

    case offer_type_enum.trial_extension:
      values = formValues as TrialExtensionOfferFormValues;

      return {
        __typename: "offer",
        id: 1,
        token: "offer",
        type: offer_type_enum.trial_extension,
        name: values.name,
        show_banner: values.showBanner,
        image_url: values.image,
        created_at: now,
        updated_at: now,
        headline_translation: {
          __typename: "translation",
          id: 1,
          translation_values: [
            {
              __typename: "translation_value",
              translation_id: 1,
              language: language_enum.en_us,
              value: values.headline,
              format: translation_value_format_enum.text,
            },
          ],
        },
        content_translation: {
          __typename: "translation",
          id: 2,
          translation_values: [
            {
              __typename: "translation_value",
              translation_id: 2,
              language: language_enum.en_us,
              value: content,
              format: values.contentFormat,
            },
          ],
        },
        button_text_translation: {
          __typename: "translation",
          id: 3,
          translation_values: [
            {
              __typename: "translation_value",
              translation_id: 3,
              language: language_enum.en_us,
              value: values.buttonText,
              format: translation_value_format_enum.text,
            },
          ],
        },
        offer_coupon: null,
        offer_change_plan: null,
        offer_trial_extension: {
          __typename: "offer_trial_extension",
          days: Number(values.days),
        },
        offer_pause_subscription: null,
        offer_custom: null,
        offer_timer: {
          __typename: "offer_timer",
          offer_timer_mode: values.timerMode as offer_timer_mode_enum,
          start_time: values.startTime
            ? values.startTime.toZonedDateTime("UTC").toInstant().toString()
            : null,
          end_time: values.endTime
            ? values.endTime.toZonedDateTime("UTC").toInstant().toString()
            : null,
          duration: values.offerLength,
          show_timer: values.countdownTimer,
        },
        goal,
        style: (values.style as offer_style_enum) || offer_style_enum.modal,
      };

    case offer_type_enum.upgrade:
      values = formValues as UpgradeOfferFormValues;

      return {
        __typename: "offer",
        id: 1,
        token: "offer",
        type: offer_type_enum.upgrade,
        name: values.name,
        show_banner: values.showBanner,
        image_url: values.image,
        created_at: now,
        updated_at: now,
        headline_translation: {
          __typename: "translation",
          id: 1,
          translation_values: [
            {
              __typename: "translation_value",
              translation_id: 1,
              language: language_enum.en_us,
              value: values.headline,
              format: translation_value_format_enum.text,
            },
          ],
        },
        content_translation: {
          __typename: "translation",
          id: 2,
          translation_values: [
            {
              __typename: "translation_value",
              translation_id: 2,
              language: language_enum.en_us,
              value: content,
              format: values.contentFormat,
            },
          ],
        },
        button_text_translation: {
          __typename: "translation",
          id: 3,
          translation_values: [
            {
              __typename: "translation_value",
              translation_id: 3,
              language: language_enum.en_us,
              value: values.buttonText,
              format: translation_value_format_enum.text,
            },
          ],
        },
        offer_upgrade: {
          __typename: "offer_upgrade",
          platform_plan_id: values.planId,
          change_at: values.changeAt as offer_change_plan_change_at_enum,
        },
        offer_trial_extension: null,
        offer_pause_subscription: null,
        offer_custom: null,
        goal,
        style: (values.style as offer_style_enum) || offer_style_enum.modal,
      };

    default:
      throw new UnreachableCaseError(offerType);
  }
};

export default mapPreviewOffer;
