import { platform_enum } from "../../../../__generated__/graphql";
import FieldCheckboxInput from "../../../../common/form/FieldCheckboxInput";
import FieldHint from "../../../../common/form/FieldHint";
import FieldInput from "../../../../common/form/FieldInput";
import FieldLabel from "../../../../common/form/FieldLabel";
import FieldRow from "../../../../common/form/FieldRow";
import FieldSet from "../../../../common/form/FieldSet";
import FormMode from "../../../../common/form/FormMode";
import TextInput from "../../../../common/form/input/TextInput";
import useTranslatableFormContext from "../../../../common/form/useTranslatableFormContext";
import HelpIcon from "../../../../common/HelpIcon";
import {
  PlatformFeature,
  platformHasFeature,
} from "../../../../common/platform/features";
import { TrialExtensionOfferFormValues } from "../types";

interface TrialExtensionDetailsFormPartialProps {
  mode: FormMode;
  isSubmitting: boolean;
  platform: platform_enum;
}

const TrialExtensionDetailsFormPartial: React.FunctionComponent<
  TrialExtensionDetailsFormPartialProps
> = ({ mode, isSubmitting, platform }) => {
  const { register, formState } =
    useTranslatableFormContext<TrialExtensionOfferFormValues>();

  return (
    <>
      <FieldSet
        title="Extension details"
        disabled={isSubmitting || mode === "edit"}
      >
        <FieldRow>
          <FieldLabel>
            <label>Days</label>
          </FieldLabel>
          <FieldInput>
            <TextInput
              {...register("days", {
                required: true,
                validate: (value) => Number(value) > 0,
              })}
              id="days"
              width="xs"
              fieldError={formState.errors.days}
            />
            <FieldHint>Days by which to extend the trial.</FieldHint>
          </FieldInput>
        </FieldRow>
      </FieldSet>
      {platformHasFeature(
        platform,
        PlatformFeature.TrialExtensionAllowWithoutTrial
      ) && (
        <FieldRow>
          <FieldLabel>
            <label>
              Without trial{" "}
              <HelpIcon content="By default, trial extension offers are only shown to subscribers with an active trial." />
            </label>
          </FieldLabel>
          <FieldCheckboxInput>
            <input
              {...register("allowWithoutTrial")}
              id="allowWithoutTrial"
              type="checkbox"
            />
            <label htmlFor="allowWithoutTrial">
              Allow without active trial
            </label>
          </FieldCheckboxInput>
        </FieldRow>
      )}
    </>
  );
};

export default TrialExtensionDetailsFormPartial;
