import { gql, useMutation, useQuery } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { nanoid } from "nanoid";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useToasts } from "react-toast-notifications";

import {
  AppPropertyFragment,
  property_entity_enum,
  property_format_enum,
  property_type_enum,
  PropertyPanelExistingPropertiesQuery,
  PropertyPanelMutation,
  PropertyPanelMutationVariables,
  PropertyPanelUpdateMutation,
  PropertyPanelUpdateMutationVariables,
} from "../../__generated__/graphql";
import Button from "../../common/form/Button";
import FieldError from "../../common/form/FieldError";
import FieldHint from "../../common/form/FieldHint";
import FieldInput from "../../common/form/FieldInput";
import FieldLabel from "../../common/form/FieldLabel";
import FieldRow from "../../common/form/FieldRow";
import FormMode from "../../common/form/FormMode";
import TextInput from "../../common/form/input/TextInput";
import TheAppPropertyFragment from "../../common/fragments/AppPropertyFragment";
import Panel, { PanelProps } from "../../common/panel/Panel";
import PanelButtons from "../../common/panel/PanelButtons";
import PanelFormBody from "../../common/panel/PanelFormBody";
import PanelTitle from "../../common/panel/PanelTitle";
import PillRadio from "../../common/PillRadio";
import useFocusFirstEmptyInput from "../../common/useFocusFirstEmptyInput";
import ConfirmConvertPropertyTypeModal from "./ConfirmConvertPropertyTypeModal";
import propertyTypeIcon from "./lib/propertyTypeIcon";
import propertyTypeLabel from "./lib/propertyTypeLabel";

type PropertyPanelProps = PanelProps & {
  mode: FormMode;
  property?: AppPropertyFragment;
  defaultName?: string;
  defaultEntity?: string;
  defaultType?: string;
  defaultNumberFormat?: string;
  objectTypeDisabled?: boolean;
  typesDisabled?: boolean;
  onClose: (property: AppPropertyFragment | undefined) => void;
  top?: number;
};

interface FormValues {
  name: string;
  key: string;
  entity: string;
  type: string;
  numberFormat: string;
}

const PropertyPanel: React.FunctionComponent<PropertyPanelProps> = ({
  mode,
  property,
  defaultName,
  defaultEntity,
  defaultType,
  defaultNumberFormat,
  objectTypeDisabled = false,
  typesDisabled = false,
  onClose,
  top = 0,
  ...props
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { register, formState, control, handleSubmit, reset, watch, setValue } =
    useForm<FormValues>({
      defaultValues: {
        name: property?.name || defaultName || "",
        key: property?.key || "",
        entity: property?.entity || defaultEntity || "subscriber",
        type: property?.type || defaultType || "text",
        numberFormat: property?.format || defaultNumberFormat || "unformatted",
      },
    });
  const [formRef, setFormRef] = useState<HTMLFormElement | null>(null);
  useFocusFirstEmptyInput(formRef);
  const { addToast } = useToasts();

  const [keyTouched, setKeyTouched] = useState(false);

  const [confirmConvertModalIsOpen, setConfirmConvertModalIsOpen] =
    useState(false);
  const [confirmConvertModalKey, setConfirmConvertModalKey] = useState(
    nanoid()
  );

  const ThePropertyPanelExistingPropertiesQuery = gql`
    query PropertyPanelExistingPropertiesQuery {
      property(where: { deleted_at: { _is_null: true } }) {
        id
        key
        name
      }
    }
  `;

  const {
    data: existingPropertiesData,
    loading: existingPropertiesLoading,
    refetch: refetchExistingProperties,
  } = useQuery<PropertyPanelExistingPropertiesQuery>(
    ThePropertyPanelExistingPropertiesQuery
  );

  useEffect(() => {
    if (props.isOpen) {
      //refetchExistingProperties();
    }
  }, [props.isOpen, refetchExistingProperties]);

  useEffect(() => {
    if (defaultEntity) {
      setValue("entity", defaultEntity);
    }
  }, [defaultEntity, setValue]);

  const name = watch("name");
  const keyVal = watch("key");
  const type = watch("type");
  const numberFormat = watch("numberFormat");

  const [createProperty] = useMutation<
    PropertyPanelMutation,
    PropertyPanelMutationVariables
  >(
    gql`
      mutation PropertyPanelMutation($object: property_insert_input!) {
        insert_property_one(object: $object) {
          ...AppPropertyFragment
        }
      }
      ${TheAppPropertyFragment}
    `,
    {
      update: (cache, { data }) => {
        const newProperty = data?.insert_property_one;
        if (!newProperty) {
          return;
        }

        const result = cache.readQuery<PropertyPanelExistingPropertiesQuery>({
          query: ThePropertyPanelExistingPropertiesQuery,
        });
        if (!result?.property.length) {
          return;
        }

        cache.writeQuery<PropertyPanelExistingPropertiesQuery>({
          query: ThePropertyPanelExistingPropertiesQuery,
          data: {
            property: [...result.property, newProperty],
          },
        });
      },
    }
  );

  const [updateProperty] = useMutation<
    PropertyPanelUpdateMutation,
    PropertyPanelUpdateMutationVariables
  >(
    gql`
      mutation PropertyPanelUpdateMutation(
        $id: Int!
        $name: String!
        $type: property_type_enum!
        $format: property_format_enum
      ) {
        update_property_by_pk(
          pk_columns: { id: $id }
          _set: { name: $name, type: $type, format: $format }
        ) {
          ...AppPropertyFragment
        }
      }
      ${TheAppPropertyFragment}
    `,
    {
      update: (cache, { data }) => {
        const updatedProperty = data?.update_property_by_pk;
        if (!updatedProperty) {
          return;
        }

        const result = cache.readQuery<PropertyPanelExistingPropertiesQuery>({
          query: ThePropertyPanelExistingPropertiesQuery,
        });
        if (!result?.property.length) {
          return;
        }

        cache.writeQuery<PropertyPanelExistingPropertiesQuery>({
          query: ThePropertyPanelExistingPropertiesQuery,
          data: {
            property: [
              ...result.property.filter((p) => p.id !== updatedProperty.id),
              updatedProperty,
            ],
          },
        });
      },
    }
  );

  const onSubmit = (confirmed: boolean) =>
    handleSubmit(async ({ name, key, entity, type, numberFormat }) => {
      setIsSubmitting(true);

      if (mode === "create") {
        const result = await createProperty({
          variables: {
            object: {
              name: name.trim(),
              key: key.trim(),
              entity: entity as property_entity_enum,
              type: type as property_type_enum,
              format:
                numberFormat !== "unformatted"
                  ? (numberFormat as property_format_enum)
                  : null,
            },
          },
        });

        setIsSubmitting(false);

        if (result.data?.insert_property_one?.id) {
          onClose(result.data.insert_property_one);
          reset();
        } else {
          addToast(<div>Oops! The property couldn't be created.</div>, {
            appearance: "error",
          });
        }
      } else {
        if (!property) {
          throw new Error();
        }

        if (type !== property.type && !confirmed) {
          setConfirmConvertModalIsOpen(true);
          return;
        }

        await updateProperty({
          variables: {
            id: property.id,
            name: name.trim(),
            type: type as property_type_enum,
            format:
              numberFormat !== "unformatted"
                ? (numberFormat as property_format_enum)
                : null,
          },
        });

        setIsSubmitting(false);
        onClose(undefined);
      }
    });

  useEffect(() => {
    if (mode === "create" && !keyTouched) {
      setValue("key", name.replace(/[\W_]+/g, "_").toLowerCase());
    }
  }, [mode, keyTouched, name, setValue]);

  useEffect(() => {
    if (keyVal === "" && keyTouched) {
      setKeyTouched(false);
    }
  }, [keyTouched, keyVal]);

  return (
    <>
      <Panel
        width={575}
        top={top}
        {...props}
        header={
          <>
            <PanelTitle>
              {mode === "create" ? "Create property" : "Edit property"}
            </PanelTitle>
            <PanelButtons>
              <Button
                buttonType="primary"
                form="create-property"
                isLoading={isSubmitting}
              >
                Save
              </Button>
              <Button
                type="button"
                buttonType="default"
                onClick={() => onClose(undefined)}
              >
                Cancel
              </Button>
            </PanelButtons>
          </>
        }
        isLoading={existingPropertiesLoading}
      >
        <PanelFormBody>
          <form
            id="create-property"
            ref={(ref) => setFormRef(ref)}
            onSubmit={onSubmit(false)}
          >
            <FieldRow>
              <FieldLabel>
                <label htmlFor="name">Name</label>
              </FieldLabel>
              <FieldInput>
                <TextInput
                  {...register("name", {
                    required: true,
                    validate: (value) => {
                      if (
                        value.trim() !== "" &&
                        existingPropertiesData?.property.find(
                          (p) =>
                            p.id !== property?.id &&
                            p.name.trim().toLowerCase() ===
                              value.trim().toLowerCase()
                        )
                      ) {
                        return "A property with that name already exists.";
                      }
                    },
                  })}
                  width="full"
                  fieldError={formState.errors.name}
                />
                <FieldError error={formState.errors.name} />
              </FieldInput>
            </FieldRow>
            <FieldRow>
              <FieldLabel>
                <label>Unique identifier</label>
              </FieldLabel>
              <FieldInput>
                <TextInput
                  {...register("key", {
                    required: true,
                    validate: (value) => {
                      if (
                        value.trim() !== "" &&
                        existingPropertiesData?.property.find(
                          (p) =>
                            p.id !== property?.id &&
                            p.key.trim().toLowerCase() ===
                              value.trim().toLowerCase()
                        )
                      ) {
                        return "A property with that key already exists.";
                      }
                    },
                  })}
                  width="full"
                  fieldError={formState.errors.name}
                  onKeyDown={() => setKeyTouched(true)}
                  disabled={mode === "edit"}
                />
                <FieldHint>
                  Unique identifier used when referring to the property. Cannot
                  be changed.
                </FieldHint>
                <FieldError error={formState.errors.name} />
              </FieldInput>
            </FieldRow>
            <fieldset
              disabled={objectTypeDisabled || typesDisabled || mode === "edit"}
            >
              <FieldRow>
                <FieldLabel>
                  <label>Object type</label>
                </FieldLabel>
                <FieldInput tw="flex items-center">
                  <Controller
                    control={control}
                    name="entity"
                    render={({ field }) => (
                      <PillRadio
                        value={field.value}
                        onChange={field.onChange}
                        options={[
                          {
                            label: "Subscriber",
                            value: "subscriber",
                          },
                          {
                            label: "Subscription",
                            value: "subscription",
                          },
                        ]}
                      />
                    )}
                  />
                </FieldInput>
              </FieldRow>
            </fieldset>
            <fieldset disabled={typesDisabled}>
              <FieldRow>
                <FieldLabel>
                  <label>Property type</label>
                </FieldLabel>
                <FieldInput tw="flex items-center">
                  <Controller
                    control={control}
                    name="type"
                    render={({ field }) => (
                      <PillRadio
                        value={field.value}
                        onChange={field.onChange}
                        options={[
                          {
                            label: (
                              <>
                                <FontAwesomeIcon
                                  icon={propertyTypeIcon(
                                    property_type_enum.text
                                  )}
                                  fixedWidth
                                  transform="shrink-3"
                                />{" "}
                                {propertyTypeLabel(property_type_enum.text)}
                              </>
                            ),
                            value: "text",
                          },
                          {
                            label: (
                              <>
                                <FontAwesomeIcon
                                  icon={propertyTypeIcon(
                                    property_type_enum.number
                                  )}
                                  fixedWidth
                                  transform="shrink-3"
                                />{" "}
                                {propertyTypeLabel(property_type_enum.number)}
                              </>
                            ),
                            value: "number",
                          },
                          {
                            label: (
                              <>
                                <FontAwesomeIcon
                                  icon={propertyTypeIcon(
                                    property_type_enum.boolean
                                  )}
                                  fixedWidth
                                  transform="shrink-3"
                                />{" "}
                                {propertyTypeLabel(property_type_enum.boolean)}
                              </>
                            ),
                            value: "boolean",
                          },
                          {
                            label: (
                              <>
                                <FontAwesomeIcon
                                  icon={propertyTypeIcon(
                                    property_type_enum.date
                                  )}
                                  fixedWidth
                                  transform="shrink-3"
                                />{" "}
                                {propertyTypeLabel(property_type_enum.date)}
                              </>
                            ),
                            value: "date",
                          },
                        ]}
                      />
                    )}
                  />
                </FieldInput>
              </FieldRow>
            </fieldset>
            {type === "number" && (
              <FieldRow>
                <FieldLabel>
                  <label>Number format</label>
                </FieldLabel>
                <FieldInput>
                  <Controller
                    control={control}
                    name="numberFormat"
                    render={({ field }) => (
                      <PillRadio
                        tw="leading-none mt-2"
                        value={field.value}
                        onChange={field.onChange}
                        options={[
                          {
                            label: "Unformatted",
                            value: "unformatted",
                          },
                          {
                            label: "Formatted number",
                            value: "number",
                          },
                          {
                            label: "Currency",
                            value: "currency",
                          },
                        ]}
                      />
                    )}
                  />
                  <FieldHint tw="mt-2">
                    {numberFormat === "unformatted"
                      ? "Displayed without any formatting, e.g. 100000."
                      : numberFormat === "number"
                      ? "Displayed as a formatted number, e.g. 100,000."
                      : numberFormat === "currency"
                      ? "Displayed as a currency, e.g. $100,000."
                      : null}
                  </FieldHint>
                </FieldInput>
              </FieldRow>
            )}
          </form>
        </PanelFormBody>
      </Panel>
      <ConfirmConvertPropertyTypeModal
        key={confirmConvertModalKey}
        isOpen={confirmConvertModalIsOpen}
        onClose={(confirmed) => {
          if (confirmed) {
            onSubmit(true)();
          } else {
            setIsSubmitting(false);
          }

          setConfirmConvertModalIsOpen(false);
          window.setTimeout(() => {
            setConfirmConvertModalKey(nanoid());
          }, 500);
        }}
      />
    </>
  );
};

export default PropertyPanel;
