import { gql, useMutation } from "@apollo/client";

import {
  UseUpdateOfferTrialExtensionMutation,
  UseUpdateOfferTrialExtensionMutationVariables,
} from "../../../__generated__/graphql";

export default function useUpdateOfferTrialExtension() {
  const [updateOfferTrialExtension] = useMutation<
    UseUpdateOfferTrialExtensionMutation,
    UseUpdateOfferTrialExtensionMutationVariables
  >(gql`
    mutation UseUpdateOfferTrialExtensionMutation(
      $offerId: Int!
      $allowWithoutTrial: Boolean!
    ) {
      update_offer_trial_extension_by_pk(
        pk_columns: { offer_id: $offerId }
        _set: { allow_without_trial: $allowWithoutTrial }
      ) {
        offer_id
        allow_without_trial
        offer {
          id
          offer_trial_extension {
            offer_id
            allow_without_trial
          }
        }
      }
    }
  `);

  return updateOfferTrialExtension;
}
