import gql from "graphql-tag";

import PrimarySegmentConditionGroupFragment from "./PrimarySegmentConditionGroupFragment";

export default gql`
  fragment AppSegmentFragment on segment {
    id
    account_id
    token
    name
    integration_id
    integration_segment_id
    created_at
    primary_segment_condition_group {
      ...PrimarySegmentConditionGroupFragment
    }
    integration {
      id
      type
    }
    segment_tags {
      tag {
        id
        name
      }
    }
  }
  ${PrimarySegmentConditionGroupFragment}
`;
