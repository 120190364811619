import { useEffect, useState } from "react";

import {
  language_enum,
  offer_goal_enum,
  offer_type_enum,
  platform_enum,
} from "../../../__generated__/graphql";
import FormMode from "../../../common/form/FormMode";
import { TranslatedForms } from "../../../common/form/useTranslatableForm";
import useTranslatableFormContext from "../../../common/form/useTranslatableFormContext";
import useFocusFirstEmptyInput from "../../../common/useFocusFirstEmptyInput";
import ConfirmationModalFormPartial from "./partials/ConfirmationModalFormPartial";
import OfferBaseFormPartial from "./partials/OfferBaseFormPartial";
import OfferContentFormPartial from "./partials/OfferContentFormPartial";
import OfferMetadataFormPartial from "./partials/OfferMetadataFormPartial";
import TrialExtensionDetailsFormPartial from "./partials/TrialExtensionDetailsFormPartial";
import { TrialExtensionOfferFormValues } from "./types";

interface TrialExtensionOfferFormProps {
  mode: FormMode;
  goal?: offer_goal_enum;
  isSubmitting: boolean;
  tags: string[];
  platform: platform_enum;
  onChange: (values: TranslatedForms<TrialExtensionOfferFormValues>) => void;
  onSubmit: (values: TranslatedForms<TrialExtensionOfferFormValues>) => void;
  onChangeEditingLanguage: (value: language_enum) => void;
}

const TrialExtensionOfferForm: React.FunctionComponent<
  TrialExtensionOfferFormProps
> = ({
  mode,
  onChange,
  onSubmit,
  isSubmitting,
  tags,
  platform,
  goal = offer_goal_enum.retention,
  onChangeEditingLanguage,
}) => {
  const { register, handleSubmit, forms } =
    useTranslatableFormContext<TrialExtensionOfferFormValues>();

  const [formRef, setFormRef] = useState<HTMLFormElement | null>(null);
  useFocusFirstEmptyInput(mode === "create" ? formRef : null);

  useEffect(() => {
    onChange(forms);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forms]);

  return (
    <form
      id="create-offer"
      ref={(ref) => setFormRef(ref)}
      onSubmit={handleSubmit(onSubmit)}
    >
      <input {...register("type")} type="hidden" value="trial_extension" />

      <OfferBaseFormPartial
        mode={mode}
        isSubmitting={isSubmitting}
        offerType={offer_type_enum.coupon}
        platform={platform}
        tags={tags}
        goal={goal}
      />

      <TrialExtensionDetailsFormPartial
        mode={mode}
        isSubmitting={isSubmitting}
        platform={platform}
      />

      <OfferContentFormPartial
        isSubmitting={isSubmitting}
        goal={goal}
        onChangeEditingLanguage={onChangeEditingLanguage}
      />

      {goal === "conversion" && (
        <ConfirmationModalFormPartial isSubmitting={isSubmitting} />
      )}

      <OfferMetadataFormPartial isSubmitting={isSubmitting} />
    </form>
  );
};

export default TrialExtensionOfferForm;
