import { Temporal } from "@js-temporal/polyfill";
import { UnreachableCaseError } from "ts-essentials";

import {
  platform_enum,
  property_entity_enum,
  property_type_enum,
  segment_condition_operator_enum,
  segment_condition_property_enum,
  SegmentConditionFragment,
  SubscriberSegmentPropertyValuesFragment,
  subscription_bool_exp,
  SubscriptionSegmentPropertyValuesFragment,
} from "../../__generated__/graphql";
import { countries, states } from "../../__generated__/locations";
import formatCurrency from "../formatCurrency";

export const getOperatorDisplay = (
  property: segment_condition_property_enum,
  operator: `${segment_condition_operator_enum}`
) => {
  const isArray =
    property === "custom_property" ? false : propertyConfigs[property].array;

  switch (operator) {
    case "eq":
      return isArray ? "has at least one equal to" : "is equal to";

    case "neq":
      return isArray ? "has none equal to" : "is not equal to";

    case "gt":
      return isArray ? "has at least one greater than" : "is greater than";

    case "lt":
      return isArray ? "has at least one less than" : "is less than";

    case "gte":
      return isArray
        ? "has at least one greater than or equal to"
        : "is greater than or equal to";

    case "lte":
      return isArray
        ? "has at least one less than or equal to"
        : "is less than or equal to";

    case "contains":
      return isArray ? "has at least one that contains" : "contains";

    case "starts_with":
      return isArray ? "has at least one that starts with" : "starts with";

    case "ends_with":
      return isArray ? "has at least one that ends with" : "ends with";

    case "in":
      return isArray ? "has at least one that is one of" : "is one of";

    case "nin":
      return isArray ? "has none that are one of" : "is not one of";

    case "is_set":
      return isArray ? "has at least one that is set" : "is set";

    case "is_not_set":
      return isArray ? "has none that are set" : "is not set";

    case "within_last_days":
      return isArray
        ? "has at least one that is within the last # days"
        : "is within the last # days";

    case "more_than_days_ago":
      return isArray
        ? "has at least one that is more than # days ago"
        : "is more than # days ago";

    case "within_next_days":
      return isArray
        ? "has at least one that is within the next # days"
        : "is within the next # days";

    case "more_than_days_from_now":
      return isArray
        ? "has at least one that is more than # days from now"
        : "is more than # days from now";

    default:
      throw new UnreachableCaseError(operator);
  }
};

export type BooleanValue = boolean | null;

export type DateValue = string | null;

export type IdValue = string | null;

export type IdArrayValue = string[];

export type LocationValue = {
  country: string;
  state: string;
} | null;

export type NumberValue = string | null;

export type NumberArrayValue = string[];

export type SelectValue = string | null;

export type SelectArrayValue = string[];

export type StringValue = string | null;

export type StringArrayValue = string[];

export type TimestampValue = string | null;

export type TimestampArrayValue = string[];

export type SegmentPropertyValue =
  | BooleanValue
  | DateValue
  | IdValue
  | IdArrayValue
  | LocationValue
  | NumberValue
  | NumberArrayValue
  | SelectValue
  | SelectArrayValue
  | StringValue
  | StringArrayValue
  | TimestampValue;

export type SegmentConditionPropertyType =
  | "boolean"
  | "currency"
  | "date"
  | "id"
  | "location"
  | "number"
  | "percentage"
  | "select"
  | "string"
  | "timestamp";

export type BooleanOperator = segment_condition_operator_enum.eq;

export type CurrencyOperator =
  | segment_condition_operator_enum.eq
  | segment_condition_operator_enum.neq
  | segment_condition_operator_enum.gt
  | segment_condition_operator_enum.lt
  | segment_condition_operator_enum.gte
  | segment_condition_operator_enum.lte;

export type DateOperator =
  | segment_condition_operator_enum.eq
  | segment_condition_operator_enum.neq
  | segment_condition_operator_enum.gt
  | segment_condition_operator_enum.lt
  | segment_condition_operator_enum.gte
  | segment_condition_operator_enum.lte
  | segment_condition_operator_enum.within_last_days
  | segment_condition_operator_enum.more_than_days_ago
  | segment_condition_operator_enum.within_next_days
  | segment_condition_operator_enum.more_than_days_from_now;

export type IdOperator =
  | segment_condition_operator_enum.eq
  | segment_condition_operator_enum.neq
  | segment_condition_operator_enum.contains
  | segment_condition_operator_enum.starts_with
  | segment_condition_operator_enum.ends_with
  | segment_condition_operator_enum.in
  | segment_condition_operator_enum.nin;

export type IdArrayOperator =
  | segment_condition_operator_enum.eq
  | segment_condition_operator_enum.neq
  | segment_condition_operator_enum.contains
  | segment_condition_operator_enum.starts_with
  | segment_condition_operator_enum.ends_with
  | segment_condition_operator_enum.in
  | segment_condition_operator_enum.nin;

export type LocationOperator =
  | segment_condition_operator_enum.eq
  | segment_condition_operator_enum.neq
  | segment_condition_operator_enum.in
  | segment_condition_operator_enum.nin;

export type NumberOperator =
  | segment_condition_operator_enum.eq
  | segment_condition_operator_enum.neq
  | segment_condition_operator_enum.gt
  | segment_condition_operator_enum.lt
  | segment_condition_operator_enum.gte
  | segment_condition_operator_enum.lte;

export type NumberArrayOperator =
  | segment_condition_operator_enum.eq
  | segment_condition_operator_enum.neq
  | segment_condition_operator_enum.gt
  | segment_condition_operator_enum.lt
  | segment_condition_operator_enum.gte
  | segment_condition_operator_enum.lte;

export type PercentageOperator =
  | segment_condition_operator_enum.eq
  | segment_condition_operator_enum.neq
  | segment_condition_operator_enum.gt
  | segment_condition_operator_enum.lt
  | segment_condition_operator_enum.gte
  | segment_condition_operator_enum.lte;

export type PercentageArrayOperator =
  | segment_condition_operator_enum.eq
  | segment_condition_operator_enum.neq
  | segment_condition_operator_enum.gt
  | segment_condition_operator_enum.lt
  | segment_condition_operator_enum.gte
  | segment_condition_operator_enum.lte;

export type SelectOperator =
  | segment_condition_operator_enum.eq
  | segment_condition_operator_enum.neq;

export type SelectArrayOperator =
  | segment_condition_operator_enum.eq
  | segment_condition_operator_enum.neq;

export type StringOperator =
  | segment_condition_operator_enum.eq
  | segment_condition_operator_enum.neq
  | segment_condition_operator_enum.contains
  | segment_condition_operator_enum.starts_with
  | segment_condition_operator_enum.ends_with
  | segment_condition_operator_enum.is_set
  | segment_condition_operator_enum.is_not_set;

export type StringArrayOperator =
  | segment_condition_operator_enum.eq
  | segment_condition_operator_enum.neq
  | segment_condition_operator_enum.contains
  | segment_condition_operator_enum.starts_with
  | segment_condition_operator_enum.ends_with;

export type TimestampOperator =
  | segment_condition_operator_enum.eq
  | segment_condition_operator_enum.neq
  | segment_condition_operator_enum.gt
  | segment_condition_operator_enum.lt
  | segment_condition_operator_enum.gte
  | segment_condition_operator_enum.lte
  | segment_condition_operator_enum.within_last_days
  | segment_condition_operator_enum.more_than_days_ago
  | segment_condition_operator_enum.within_next_days
  | segment_condition_operator_enum.more_than_days_from_now;

export interface BooleanCondition extends SegmentConditionFragment {
  operator: BooleanOperator;
}

export interface CurrencyCondition extends SegmentConditionFragment {
  operator: CurrencyOperator;
}

export interface DateCondition extends SegmentConditionFragment {
  operator: DateOperator;
}

export interface IdCondition extends SegmentConditionFragment {
  operator: IdOperator;
}

export interface IdArrayCondition extends SegmentConditionFragment {
  operator: IdArrayOperator;
}

export interface LocationCondition extends SegmentConditionFragment {
  operator: LocationOperator;
}

export interface NumberCondition extends SegmentConditionFragment {
  operator: NumberOperator;
}

export interface NumberArrayCondition extends SegmentConditionFragment {
  operator: NumberArrayOperator;
}

export interface PercentageCondition extends SegmentConditionFragment {
  operator: PercentageOperator;
}

export interface PercentageArrayCondition extends SegmentConditionFragment {
  operator: PercentageArrayOperator;
}

export interface SelectCondition extends SegmentConditionFragment {
  operator: SelectOperator;
}

export interface SelectArrayCondition extends SegmentConditionFragment {
  operator: SelectArrayOperator;
}

export interface StringCondition extends SegmentConditionFragment {
  operator: StringOperator;
}

export interface StringArrayCondition extends SegmentConditionFragment {
  operator: StringArrayOperator;
}

export interface TimestampCondition extends SegmentConditionFragment {
  operator: TimestampOperator;
}

export const isBooleanValue = (value: unknown): value is BooleanValue => {
  if (value === true || value === false || value === null) {
    return true;
  }

  return false;
};

export const isBooleanCondition = (
  condition: SegmentConditionFragment
): condition is BooleanCondition => {
  if (condition.property === "custom_property") {
    return condition.custom_property?.type === "boolean";
  }

  const config = propertyConfigs[condition.property];
  return config.type === "boolean";
};

export const isDateValue = (value: unknown): value is DateValue =>
  typeof value === "string" || value === null;

export const isDateCondition = (
  condition: SegmentConditionFragment
): condition is DateCondition => {
  if (condition.property === "custom_property") {
    return condition.custom_property?.type === "date";
  }

  const config = propertyConfigs[condition.property];
  return config.type === "date";
};

export const isIdValue = (value: unknown): value is IdValue =>
  typeof value === "string" || value === null;

export const isIdCondition = (
  condition: SegmentConditionFragment
): condition is IdCondition => {
  if (condition.property === "custom_property") {
    return false;
  }

  const config = propertyConfigs[condition.property];
  return config.type === "id" && !config.array;
};

export const isIdArrayValue = (value: unknown): value is IdArrayValue =>
  Array.isArray(value) && value.every((v) => typeof v === "string");

export const isIdArrayCondition = (
  condition: SegmentConditionFragment
): condition is IdArrayCondition => {
  if (condition.property === "custom_property") {
    return false;
  }

  const config = propertyConfigs[condition.property];
  return config.type === "id" && !!config.array;
};

export const isLocationValue = (value: unknown): value is LocationValue =>
  value === null ||
  (typeof value === "object" &&
    Object.hasOwn(value, "country") &&
    Object.hasOwn(value, "state"));

export const isLocationCondition = (
  condition: SegmentConditionFragment
): condition is LocationCondition => {
  if (condition.property === "custom_property") {
    return false;
  }

  const config = propertyConfigs[condition.property];
  return config.type === "location";
};

export const isNumberValue = (value: unknown): value is NumberValue =>
  typeof value === "string" || value === null;

export const isNumberCondition = (
  condition: SegmentConditionFragment
): condition is NumberCondition => {
  if (condition.property === "custom_property") {
    return condition.custom_property?.type === "number";
  }

  const config = propertyConfigs[condition.property];
  return (
    (config.type === "number" ||
      config.type === "currency" ||
      config.type === "percentage") &&
    !config.array
  );
};

export const isNumberArrayValue = (value: unknown): value is NumberArrayValue =>
  Array.isArray(value) && value.every((v) => typeof v === "string");

export const isNumberArrayCondition = (
  condition: SegmentConditionFragment
): condition is NumberArrayCondition => {
  if (condition.property === "custom_property") {
    return false;
  }

  const config = propertyConfigs[condition.property];
  return (
    (config.type === "number" ||
      config.type === "currency" ||
      config.type === "percentage") &&
    config.array
  );
};

export const isSelectValue = (value: unknown): value is SelectValue =>
  typeof value === "string" || value === null;

export const isSelectCondition = (
  condition: SegmentConditionFragment
): condition is SelectCondition => {
  if (condition.property === "custom_property") {
    return false;
  }

  const config = propertyConfigs[condition.property];
  return config.type === "select" && !config.array;
};

export const isSelectArrayValue = (value: unknown): value is SelectArrayValue =>
  Array.isArray(value) && value.every((v) => typeof v === "string");

export const isSelectArrayCondition = (
  condition: SegmentConditionFragment
): condition is SelectArrayCondition => {
  if (condition.property === "custom_property") {
    return false;
  }

  const config = propertyConfigs[condition.property];
  return config.type === "select" && config.array;
};

export const isStringValue = (value: unknown): value is StringValue =>
  typeof value === "string" || value === null;

export const isStringCondition = (
  condition: SegmentConditionFragment
): condition is StringCondition => {
  if (condition.property === "custom_property") {
    return condition.custom_property?.type === "text";
  }

  const config = propertyConfigs[condition.property];
  return config.type === "string" && !config.array;
};

export const isStringArrayValue = (value: unknown): value is StringArrayValue =>
  Array.isArray(value) && value.every((v) => typeof v === "string");

export const isStringArrayCondition = (
  condition: SegmentConditionFragment
): condition is StringArrayCondition => {
  if (condition.property === "custom_property") {
    return false;
  }

  const config = propertyConfigs[condition.property];
  return config.type === "string" && !!config.array;
};

export const isTimestampValue = (value: unknown): value is TimestampValue =>
  typeof value === "string" || value === null;

export const isTimestampCondition = (
  condition: SegmentConditionFragment
): condition is CurrencyCondition => {
  if (condition.property === "custom_property") {
    return condition.custom_property?.type === "timestamp";
  }

  return false; // No timestamps are used, yet.
};

export type SegmentPropertyKey = Exclude<
  segment_condition_property_enum,
  "custom_property"
>;

export type SegmentWhereHandler = (
  operator: segment_condition_operator_enum,
  value: any
) => subscription_bool_exp;

export interface SegmentConditionProperty {
  id: segment_condition_property_enum;
  group: "Product" | "Plan" | "Add-on" | "Subscriber" | "Subscription";
  name: string;
  type: SegmentConditionPropertyType;
  excludeFromPlatforms: platform_enum[];
  options?: SelectOption[];
  description?: string;
  many?: boolean;
}

export interface SelectOption {
  id: string;
  label: string;
}

const daysSelectOptions = [
  {
    id: "sunday",
    label: "Sunday",
  },
  {
    id: "monday",
    label: "Monday",
  },
  {
    id: "tuesday",
    label: "Tuesday",
  },
  {
    id: "wednesday",
    label: "Wednesday",
  },
  {
    id: "thursday",
    label: "Thursday",
  },
  {
    id: "friday",
    label: "Friday",
  },
  {
    id: "saturday",
    label: "Saturday",
  },
];

interface SegmentConditionPropertyConfigBase {
  id: segment_condition_property_enum;
  entity: property_entity_enum;
  name: string;
  platforms: Record<platform_enum, boolean>;
  array: boolean;
  description?: string;
  clickHouseDataType?: string;
}

interface SegmentConditionPropertyDefaultConfig
  extends SegmentConditionPropertyConfigBase {
  type: Exclude<SegmentConditionPropertyType, "select">;
}

interface SegmentConditionSelectPropertyConfig
  extends SegmentConditionPropertyConfigBase {
  type: "select";
  options: SelectOption[];
}

export type SegmentConditionPropertyConfig =
  | SegmentConditionPropertyDefaultConfig
  | SegmentConditionSelectPropertyConfig;

export const ALL_PLATFORMS: Record<platform_enum, boolean> = {
  bold: true,
  braintree: true,
  chargebee: true,
  chargify: true,
  custom: true,
  naviga: true,
  openpay: true,
  ordergroove: true,
  paddle: true,
  recharge: true,
  recurly: true,
  stripe: true,
  submarine: true,
  zuora: true,
};

const ALL_PLATFORMS_EXCEPT_CUSTOM: Record<platform_enum, boolean> = {
  bold: true,
  braintree: true,
  chargebee: true,
  chargify: true,
  custom: false,
  naviga: true,
  openpay: true,
  ordergroove: true,
  paddle: true,
  recharge: true,
  recurly: true,
  stripe: true,
  submarine: true,
  zuora: true,
};

const PLATFORMS_WITH_ADD_ONS: Record<platform_enum, boolean> = {
  bold: false,
  braintree: false,
  chargebee: true,
  chargify: true,
  custom: false,
  naviga: false,
  openpay: false,
  ordergroove: false,
  paddle: false,
  recharge: false,
  recurly: true,
  stripe: false,
  submarine: false,
  zuora: false,
};

const PLATFORMS_WITH_PRODUCTS: Record<platform_enum, boolean> = {
  bold: false,
  braintree: false,
  chargebee: false,
  chargify: true,
  custom: false,
  naviga: false,
  openpay: true,
  ordergroove: false,
  paddle: false,
  recharge: false,
  recurly: false,
  stripe: true,
  submarine: false,
  zuora: true,
};

const NEWSPAPER_PLATFORMS: Record<platform_enum, boolean> = {
  bold: false,
  braintree: false,
  chargebee: false,
  chargify: false,
  custom: false,
  naviga: true,
  openpay: false,
  ordergroove: false,
  paddle: false,
  recharge: false,
  recurly: false,
  stripe: false,
  submarine: false,
  zuora: false,
};

const ECOMMERCE_PLATFORMS: Record<platform_enum, boolean> = {
  bold: true,
  braintree: false,
  chargebee: false,
  chargify: false,
  custom: false,
  naviga: false,
  openpay: false,
  ordergroove: true,
  paddle: false,
  recharge: true,
  recurly: false,
  stripe: false,
  submarine: true,
  zuora: false,
};

export const propertyConfigs = {
  [segment_condition_property_enum.addon_id]: {
    id: segment_condition_property_enum.addon_id,
    type: "id",
    array: true,
    entity: property_entity_enum.subscription,
    name: "Add-on ID",
    platforms: PLATFORMS_WITH_ADD_ONS,
  },
  [segment_condition_property_enum.addon_name]: {
    id: segment_condition_property_enum.addon_name,
    type: "string",
    array: true,
    entity: property_entity_enum.subscription,
    name: "Add-on name",
    platforms: PLATFORMS_WITH_ADD_ONS,
  },
  [segment_condition_property_enum.newspaper_subscription_current_balance]: {
    id: segment_condition_property_enum.newspaper_subscription_current_balance,
    type: "currency",
    array: false,
    entity: property_entity_enum.subscription,
    name: "Current balance",
    platforms: NEWSPAPER_PLATFORMS,
  },
  [segment_condition_property_enum.newspaper_subscription_digital_frequency]: {
    id: segment_condition_property_enum.newspaper_subscription_digital_frequency,
    type: "select",
    array: true,
    options: daysSelectOptions,
    entity: property_entity_enum.subscription,
    name: "Digital distribution day",
    platforms: NEWSPAPER_PLATFORMS,
    clickHouseDataType:
      "Enum('monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday')",
  },
  [segment_condition_property_enum.newspaper_subscription_is_digital]: {
    id: segment_condition_property_enum.newspaper_subscription_is_digital,
    type: "boolean",
    array: false,
    entity: property_entity_enum.subscription,
    name: "Is digital",
    platforms: NEWSPAPER_PLATFORMS,
  },
  [segment_condition_property_enum.newspaper_subscription_is_ez_pay]: {
    id: segment_condition_property_enum.newspaper_subscription_is_ez_pay,
    type: "boolean",
    array: false,
    entity: property_entity_enum.subscription,
    name: "Is EZPay",
    platforms: NEWSPAPER_PLATFORMS,
  },
  [segment_condition_property_enum.newspaper_subscription_is_print]: {
    id: segment_condition_property_enum.newspaper_subscription_is_print,
    type: "boolean",
    array: false,
    entity: property_entity_enum.subscription,
    name: "Is print",
    platforms: NEWSPAPER_PLATFORMS,
  },
  [segment_condition_property_enum.newspaper_subscription_last_payment_amount]:
    {
      id: segment_condition_property_enum.newspaper_subscription_last_payment_amount,
      type: "currency",
      array: false,
      entity: property_entity_enum.subscription,
      name: "Last payment amount",
      platforms: NEWSPAPER_PLATFORMS,
    },
  [segment_condition_property_enum.newspaper_subscription_newspaper_name]: {
    id: segment_condition_property_enum.newspaper_subscription_newspaper_name,
    type: "string",
    array: false,
    entity: property_entity_enum.subscription,
    name: "Paper name",
    platforms: NEWSPAPER_PLATFORMS,
  },
  [segment_condition_property_enum.newspaper_subscription_paper_code]: {
    id: segment_condition_property_enum.newspaper_subscription_paper_code,
    type: "string",
    array: false,
    entity: property_entity_enum.subscription,
    name: "Paper code",
    platforms: NEWSPAPER_PLATFORMS,
  },
  [segment_condition_property_enum.newspaper_subscription_print_frequency]: {
    id: segment_condition_property_enum.newspaper_subscription_print_frequency,
    type: "select",
    array: true,
    options: daysSelectOptions,
    entity: property_entity_enum.subscription,
    name: "Print distribution day",
    platforms: NEWSPAPER_PLATFORMS,
  },
  [segment_condition_property_enum.newspaper_subscription_rate_code]: {
    id: segment_condition_property_enum.newspaper_subscription_rate_code,
    type: "string",
    array: false,
    entity: property_entity_enum.subscription,
    name: "Rate code",
    platforms: NEWSPAPER_PLATFORMS,
  },
  [segment_condition_property_enum.newspaper_subscription_start_source]: {
    id: segment_condition_property_enum.newspaper_subscription_start_source,
    type: "string",
    array: false,
    entity: property_entity_enum.subscription,
    name: "Start source",
    platforms: NEWSPAPER_PLATFORMS,
  },
  [segment_condition_property_enum.newspaper_subscription_stop_date]: {
    id: segment_condition_property_enum.newspaper_subscription_stop_date,
    type: "date",
    array: false,
    entity: property_entity_enum.subscription,
    name: "Stop date",
    platforms: NEWSPAPER_PLATFORMS,
  },
  [segment_condition_property_enum.plan_code]: {
    id: segment_condition_property_enum.plan_code,
    type: "string",
    array: true,
    entity: property_entity_enum.subscription,
    name: "Plan code",
    platforms: {
      bold: false,
      braintree: false,
      chargebee: false,
      chargify: false,
      custom: false,
      naviga: false,
      openpay: false,
      ordergroove: false,
      paddle: false,
      recharge: false,
      recurly: true,
      stripe: false,
      submarine: false,
      zuora: false,
    },
  },
  [segment_condition_property_enum.plan_id]: {
    id: segment_condition_property_enum.plan_id,
    type: "id",
    array: true,
    entity: property_entity_enum.subscription,
    name: "Plan IDs",
    platforms: ALL_PLATFORMS_EXCEPT_CUSTOM,
  },
  [segment_condition_property_enum.plan_interval]: {
    id: segment_condition_property_enum.plan_interval,
    type: "select",
    array: true,
    options: [
      {
        id: "day",
        label: "Day",
      },
      {
        id: "week",
        label: "Week",
      },
      {
        id: "month",
        label: "Month",
      },
      {
        id: "year",
        label: "Year",
      },
    ],
    name: "Plan intervals",
    description: "Billing interval (monthly, yearly, etc.)",
    entity: property_entity_enum.subscription,
    platforms: ALL_PLATFORMS_EXCEPT_CUSTOM,
  },
  [segment_condition_property_enum.plan_interval_count]: {
    id: segment_condition_property_enum.plan_interval_count,
    type: "number",
    array: true,
    entity: property_entity_enum.subscription,
    name: "Plan interval counts",
    platforms: ALL_PLATFORMS_EXCEPT_CUSTOM,
    description: "Count of billing interval (e.g. every 3 months)",
  },
  [segment_condition_property_enum.plan_name]: {
    id: segment_condition_property_enum.plan_name,
    type: "string",
    array: true,
    name: "Plan names",
    entity: property_entity_enum.subscription,
    platforms: ALL_PLATFORMS_EXCEPT_CUSTOM,
  },
  [segment_condition_property_enum.plan_quantity]: {
    id: segment_condition_property_enum.plan_quantity,
    type: "number",
    array: true,
    name: "Plan quantities",
    entity: property_entity_enum.subscription,
    platforms: ALL_PLATFORMS_EXCEPT_CUSTOM,
  },
  [segment_condition_property_enum.product_id]: {
    id: segment_condition_property_enum.product_id,
    type: "id",
    array: true,
    name: "Product IDs",
    entity: property_entity_enum.subscription,
    platforms: PLATFORMS_WITH_PRODUCTS,
  },
  [segment_condition_property_enum.product_name]: {
    id: segment_condition_property_enum.product_name,
    type: "string",
    array: true,
    name: "Product names",
    entity: property_entity_enum.subscription,
    platforms: PLATFORMS_WITH_PRODUCTS,
  },
  [segment_condition_property_enum.subscriber_arr]: {
    id: segment_condition_property_enum.subscriber_arr,
    type: "currency",
    array: false,
    name: "ARR",
    entity: property_entity_enum.subscriber,
    platforms: ALL_PLATFORMS,
    description:
      "Total annual recurring revenue for all subscriber's subscriptions",
  },
  [segment_condition_property_enum.subscriber_billing_location]: {
    id: segment_condition_property_enum.subscriber_billing_location,
    type: "location",
    array: false,
    name: "Billing location",
    entity: property_entity_enum.subscriber,
    platforms: {
      bold: false,
      braintree: false,
      chargebee: true,
      chargify: false,
      custom: false,
      naviga: false,
      openpay: true,
      ordergroove: false,
      paddle: false,
      recharge: false,
      recurly: true,
      stripe: true,
      submarine: false,
      zuora: false,
    },
  },
  [segment_condition_property_enum.subscriber_coupon_id]: {
    id: segment_condition_property_enum.subscriber_coupon_id,
    type: "id",
    array: false,
    name: "Active coupon ID",
    entity: property_entity_enum.subscriber,
    platforms: {
      bold: false,
      braintree: false,
      chargebee: false,
      chargify: false,
      custom: false,
      naviga: false,
      openpay: true,
      ordergroove: false,
      paddle: false,
      recharge: false,
      recurly: false,
      stripe: true,
      submarine: false,
      zuora: false,
    },
  },
  [segment_condition_property_enum.subscriber_days_since_saved]: {
    id: segment_condition_property_enum.subscriber_days_since_saved,
    type: "number",
    array: false,
    name: "Days since saved",
    entity: property_entity_enum.subscriber,
    platforms: ALL_PLATFORMS,
    description: "Number of days since the subscriber last accepted an offer",
  },
  [segment_condition_property_enum.subscriber_first_name]: {
    id: segment_condition_property_enum.subscriber_first_name,
    type: "string",
    array: false,
    name: "First name",
    entity: property_entity_enum.subscriber,
    platforms: ALL_PLATFORMS,
  },
  [segment_condition_property_enum.subscriber_last_name]: {
    id: segment_condition_property_enum.subscriber_last_name,
    type: "string",
    array: false,
    name: "Last name",
    entity: property_entity_enum.subscriber,
    platforms: ALL_PLATFORMS,
  },
  [segment_condition_property_enum.subscriber_email]: {
    id: segment_condition_property_enum.subscriber_email,
    type: "string",
    array: false,
    name: "Email address",
    entity: property_entity_enum.subscriber,
    platforms: ALL_PLATFORMS,
  },
  [segment_condition_property_enum.subscriber_paid_invoice_count]: {
    id: segment_condition_property_enum.subscriber_paid_invoice_count,
    type: "number",
    array: false,
    name: "Paid invoices",
    entity: property_entity_enum.subscriber,
    platforms: ALL_PLATFORMS_EXCEPT_CUSTOM,
    description: "Total number of paid invoices for the subscriber",
  },
  [segment_condition_property_enum.subscriber_location]: {
    id: segment_condition_property_enum.subscriber_location,
    type: "location",
    array: false,
    name: "Location",
    entity: property_entity_enum.subscriber,
    platforms: {
      bold: true,
      braintree: false,
      chargebee: false,
      chargify: false,
      custom: false,
      naviga: false,
      openpay: true,
      ordergroove: true,
      paddle: false,
      recharge: false,
      recurly: true,
      stripe: false,
      submarine: false,
      zuora: false,
    },
  },
  [segment_condition_property_enum.subscriber_ltv]: {
    id: segment_condition_property_enum.subscriber_ltv,
    type: "currency",
    array: false,
    name: "LTV",
    entity: property_entity_enum.subscriber,
    platforms: ALL_PLATFORMS_EXCEPT_CUSTOM,
    description: "Total lifetime value for all subscriber's subscriptions",
  },
  [segment_condition_property_enum.subscriber_mrr]: {
    id: segment_condition_property_enum.subscriber_mrr,
    type: "currency",
    array: false,
    name: "MRR",
    entity: property_entity_enum.subscriber,
    platforms: ALL_PLATFORMS,
    description:
      "Total monthly recurring revenue for all subscriber's subscriptions",
  },
  [segment_condition_property_enum.subscriber_offer_id]: {
    id: segment_condition_property_enum.subscriber_offer_id,
    type: "id",
    array: false,
    name: "Active offer ID",
    entity: property_entity_enum.subscriber,
    platforms: ALL_PLATFORMS,
  },
  [segment_condition_property_enum.subscriber_plan_id]: {
    id: segment_condition_property_enum.subscriber_plan_id,
    type: "id",
    array: true,
    name: "Subscription plan IDs",
    entity: property_entity_enum.subscriber,
    platforms: ALL_PLATFORMS_EXCEPT_CUSTOM,
  },
  [segment_condition_property_enum.subscriber_saved_date]: {
    id: segment_condition_property_enum.subscriber_saved_date,
    type: "date",
    array: false,
    name: "Saved date",
    entity: property_entity_enum.subscriber,
    platforms: ALL_PLATFORMS,
    description: "Date subscriber was saved",
  },
  [segment_condition_property_enum.subscriber_session_date]: {
    id: segment_condition_property_enum.subscriber_session_date,
    type: "date",
    array: false,
    name: "Session date",
    entity: property_entity_enum.subscriber,
    platforms: ALL_PLATFORMS,
    description: "Most recent session date",
  },
  [segment_condition_property_enum.subscriber_session_domain]: {
    id: segment_condition_property_enum.subscriber_session_domain,
    type: "string",
    array: false,
    name: "Session domain",
    entity: property_entity_enum.subscriber,
    platforms: ALL_PLATFORMS,
    description: "Most recent session domain",
  },
  [segment_condition_property_enum.subscriber_session_url]: {
    id: segment_condition_property_enum.subscriber_session_url,
    type: "string",
    array: false,
    name: "Session URL",
    entity: property_entity_enum.subscriber,
    platforms: ALL_PLATFORMS,
    description: "Most recent session URL",
  },
  [segment_condition_property_enum.subscriber_shipping_location]: {
    id: segment_condition_property_enum.subscriber_shipping_location,
    type: "location",
    array: false,
    name: "Shipping location",
    entity: property_entity_enum.subscriber,
    platforms: {
      bold: false,
      braintree: false,
      chargebee: false,
      chargify: true,
      custom: false,
      naviga: false,
      openpay: false,
      ordergroove: true,
      paddle: false,
      recharge: true,
      recurly: false,
      stripe: true,
      submarine: false,
      zuora: false,
    },
  },
  [segment_condition_property_enum.subscriber_status]: {
    id: segment_condition_property_enum.subscriber_status,
    type: "select",
    array: false,
    options: [
      {
        id: "active",
        label: "Active",
      },
      {
        id: "saved",
        label: "Saved",
      },
      {
        id: "deflected",
        label: "Deflected",
      },
      {
        id: "reactivated",
        label: "Reactivated",
      },
      {
        id: "canceled",
        label: "Canceled",
      },
    ],
    name: "Status",
    entity: property_entity_enum.subscriber,
    platforms: ALL_PLATFORMS,
  },
  [segment_condition_property_enum.subscriber_tags]: {
    id: segment_condition_property_enum.subscriber_tags,
    type: "string",
    array: true,
    name: "Tags",
    entity: property_entity_enum.subscriber,
    platforms: {
      bold: false,
      braintree: false,
      chargebee: false,
      chargify: false,
      custom: false,
      naviga: false,
      openpay: false,
      ordergroove: false,
      paddle: false,
      recharge: true,
      recurly: false,
      stripe: false,
      submarine: false,
      zuora: false,
    },
  },
  [segment_condition_property_enum.subscriber_variant_sku]: {
    id: segment_condition_property_enum.subscriber_variant_sku,
    type: "string",
    array: true,
    name: "SKUs",
    entity: property_entity_enum.subscriber,
    platforms: ECOMMERCE_PLATFORMS,
    description: "All SKUs for the susbcriber's subscriptions",
  },
  [segment_condition_property_enum.subscription_arr]: {
    id: segment_condition_property_enum.subscription_arr,
    type: "currency",
    array: false,
    name: "ARR",
    entity: property_entity_enum.subscription,
    platforms: ALL_PLATFORMS,
    description: "Monthly recurring revenue for the subscription",
  },
  [segment_condition_property_enum.subscription_billing_location]: {
    id: segment_condition_property_enum.subscription_billing_location,
    type: "location",
    array: false,
    name: "Billing location",
    entity: property_entity_enum.subscription,
    platforms: {
      bold: true,
      braintree: false,
      chargebee: false,
      chargify: true,
      custom: false,
      naviga: true,
      openpay: false,
      ordergroove: true,
      paddle: false,
      recharge: false,
      recurly: false,
      stripe: false,
      submarine: false,
      zuora: false,
    },
  },
  [segment_condition_property_enum.subscription_coupon_discount_fixed_amount]: {
    id: segment_condition_property_enum.subscription_coupon_discount_fixed_amount,
    type: "currency",
    array: false,
    name: "Discount fixed amount",
    entity: property_entity_enum.subscription,
    platforms: {
      bold: true,
      braintree: true,
      chargebee: true,
      chargify: true,
      custom: false,
      naviga: false,
      openpay: true,
      ordergroove: true,
      paddle: true,
      recharge: true,
      recurly: true,
      stripe: true,
      submarine: false,
      zuora: true,
    },
    description: "Discount amount of the subscription's active coupon",
  },
  [segment_condition_property_enum.subscription_coupon_discount_percentage]: {
    id: segment_condition_property_enum.subscription_coupon_discount_percentage,
    type: "percentage",
    array: false,
    name: "Discount percentage",
    entity: property_entity_enum.subscription,
    platforms: {
      bold: true,
      braintree: false,
      chargebee: true,
      chargify: true,
      custom: false,
      naviga: false,
      openpay: true,
      ordergroove: true,
      paddle: false,
      recharge: true,
      recurly: true,
      stripe: true,
      submarine: false,
      zuora: true,
    },
    description: "Discount percentage of the subscription's active coupon",
  },
  [segment_condition_property_enum.subscription_coupon_id]: {
    id: segment_condition_property_enum.subscription_coupon_id,
    type: "id",
    array: false,
    name: "Active coupon ID",
    entity: property_entity_enum.subscription,
    platforms: ALL_PLATFORMS_EXCEPT_CUSTOM,
  },
  [segment_condition_property_enum.subscription_duration]: {
    id: segment_condition_property_enum.subscription_duration,
    type: "number",
    array: false,
    name: "Duration (days)",
    entity: property_entity_enum.subscription,
    platforms: ALL_PLATFORMS_EXCEPT_CUSTOM,
    description: "Number of days since the subscription was created",
  },
  [segment_condition_property_enum.subscription_free_trial]: {
    id: segment_condition_property_enum.subscription_free_trial,
    type: "boolean",
    array: false,
    name: "Is free trial",
    entity: property_entity_enum.subscription,
    platforms: {
      bold: false,
      braintree: true,
      chargebee: true,
      chargify: true,
      custom: true,
      naviga: true,
      openpay: true,
      ordergroove: false,
      paddle: true,
      recharge: false,
      recurly: true,
      stripe: true,
      submarine: false,
      zuora: true,
    },
  },
  [segment_condition_property_enum.subscription_paid_invoice_count]: {
    id: segment_condition_property_enum.subscription_paid_invoice_count,
    type: "number",
    array: false,
    name: "Paid invoices",
    entity: property_entity_enum.subscription,
    platforms: ALL_PLATFORMS_EXCEPT_CUSTOM,
    description: "Total number of paid invoices for the subscription",
  },
  [segment_condition_property_enum.subscription_ltv]: {
    id: segment_condition_property_enum.subscription_ltv,
    type: "currency",
    array: false,
    name: "LTV",
    entity: property_entity_enum.subscription,
    platforms: ALL_PLATFORMS_EXCEPT_CUSTOM,
    description: "Lifetime value of the subscription",
  },
  [segment_condition_property_enum.subscription_mrr]: {
    id: segment_condition_property_enum.subscription_mrr,
    type: "currency",
    array: false,
    name: "MRR",
    entity: property_entity_enum.subscription,
    platforms: ALL_PLATFORMS,
    description: "Monthly recurring revenue for the subscription",
  },
  [segment_condition_property_enum.subscription_next_billing_at]: {
    id: segment_condition_property_enum.subscription_next_billing_at,
    type: "date",
    array: false,
    name: "Next billing date",
    entity: property_entity_enum.subscription,
    platforms: {
      bold: true,
      braintree: true,
      chargebee: true,
      chargify: true,
      custom: false,
      naviga: true,
      openpay: true,
      ordergroove: true,
      paddle: true,
      recharge: true,
      recurly: true,
      stripe: true,
      submarine: true,
      zuora: false,
    },
  },
  [segment_condition_property_enum.subscription_past_due]: {
    id: segment_condition_property_enum.subscription_past_due,
    type: "boolean",
    array: false,
    name: "Is past due",
    entity: property_entity_enum.subscription,
    platforms: {
      bold: false,
      braintree: true,
      chargebee: true,
      chargify: true,
      custom: false,
      naviga: true,
      openpay: true,
      ordergroove: false,
      paddle: true,
      recharge: false,
      recurly: true,
      stripe: true,
      submarine: false,
      zuora: true,
    },
  },
  [segment_condition_property_enum.subscription_session_date]: {
    id: segment_condition_property_enum.subscription_session_date,
    type: "date",
    array: false,
    name: "Session date",
    entity: property_entity_enum.subscription,
    platforms: ALL_PLATFORMS,
    description: "Most recent session date",
  },
  [segment_condition_property_enum.subscription_session_domain]: {
    id: segment_condition_property_enum.subscription_session_domain,
    type: "string",
    array: false,
    name: "Session domain",
    entity: property_entity_enum.subscription,
    platforms: ALL_PLATFORMS,
    description: "Most recent session domain",
  },
  [segment_condition_property_enum.subscription_session_url]: {
    id: segment_condition_property_enum.subscription_session_url,
    type: "string",
    array: false,
    name: "Session URL",
    entity: property_entity_enum.subscription,
    platforms: ALL_PLATFORMS,
    description: "Most recent session URL",
  },
  [segment_condition_property_enum.subscription_shipping_location]: {
    id: segment_condition_property_enum.subscription_shipping_location,
    type: "location",
    array: false,
    name: "Shipping location",
    entity: property_entity_enum.subscription,
    platforms: {
      bold: false,
      braintree: false,
      chargebee: false,
      chargify: true,
      custom: false,
      naviga: false,
      openpay: true,
      ordergroove: true,
      paddle: false,
      recharge: true,
      recurly: false,
      stripe: true,
      submarine: false,
      zuora: false,
    },
  },
  [segment_condition_property_enum.subscription_status]: {
    id: segment_condition_property_enum.subscription_status,
    type: "select",
    array: false,
    options: [
      {
        id: "trialing",
        label: "Trialing",
      },
      {
        id: "active",
        label: "Active",
      },
      {
        id: "canceled",
        label: "Canceled",
      },
    ],
    name: "Status",
    entity: property_entity_enum.subscription,
    platforms: ALL_PLATFORMS_EXCEPT_CUSTOM,
  },
  [segment_condition_property_enum.subscription_trial_days]: {
    id: segment_condition_property_enum.subscription_trial_days,
    type: "number",
    array: false,
    name: "Trial days",
    entity: property_entity_enum.subscription,
    platforms: {
      bold: false,
      braintree: false,
      chargebee: true,
      chargify: true,
      custom: false,
      naviga: false,
      openpay: true,
      ordergroove: false,
      paddle: false,
      recharge: false,
      recurly: true,
      stripe: true,
      submarine: false,
      zuora: false,
    },
    description: "Total number of trial days",
  },
  [segment_condition_property_enum.subscription_trial_end_date]: {
    id: segment_condition_property_enum.subscription_trial_end_date,
    type: "date",
    array: false,
    name: "Trial end date",
    entity: property_entity_enum.subscription,
    platforms: {
      bold: false,
      braintree: false,
      chargebee: true,
      chargify: true,
      custom: false,
      naviga: false,
      openpay: true,
      ordergroove: false,
      paddle: false,
      recharge: false,
      recurly: true,
      stripe: true,
      submarine: false,
      zuora: false,
    },
  },
  [segment_condition_property_enum.subscription_trial_start_date]: {
    id: segment_condition_property_enum.subscription_trial_start_date,
    type: "date",
    array: false,
    name: "Trial start date",
    entity: property_entity_enum.subscription,
    platforms: {
      bold: false,
      braintree: false,
      chargebee: true,
      chargify: true,
      custom: false,
      naviga: false,
      openpay: true,
      ordergroove: false,
      paddle: false,
      recharge: false,
      recurly: true,
      stripe: true,
      submarine: false,
      zuora: false,
    },
  },
  [segment_condition_property_enum.subscription_variant_sku]: {
    id: segment_condition_property_enum.subscription_variant_sku,
    type: "string",
    array: true,
    name: "SKUs",
    entity: property_entity_enum.subscription,
    platforms: ECOMMERCE_PLATFORMS,
  },
  [segment_condition_property_enum.subscription_will_renew]: {
    id: segment_condition_property_enum.subscription_will_renew,
    type: "boolean",
    array: false,
    name: "Will renew",
    entity: property_entity_enum.subscription,
    platforms: {
      bold: false,
      braintree: true,
      chargebee: true,
      chargify: true,
      custom: false,
      naviga: true,
      openpay: true,
      ordergroove: false,
      paddle: true,
      recharge: false,
      recurly: true,
      stripe: true,
      submarine: false,
      zuora: true,
    },
  },
  [segment_condition_property_enum.next_invoice_amount_due]: {
    id: segment_condition_property_enum.next_invoice_amount_due,
    type: "currency",
    array: false,
    name: "Next invoice amount due",
    entity: property_entity_enum.subscription,
    platforms: {
      bold: false,
      braintree: false,
      chargebee: false,
      chargify: false,
      custom: false,
      naviga: false,
      openpay: true,
      ordergroove: false,
      paddle: false,
      recharge: false,
      recurly: false,
      stripe: false,
      submarine: false,
      zuora: false,
    },
  },
  [segment_condition_property_enum.next_invoice_subtotal]: {
    id: segment_condition_property_enum.next_invoice_subtotal,
    type: "currency",
    array: false,
    name: "Next invoice subtotal",
    entity: property_entity_enum.subscription,
    platforms: {
      bold: false,
      braintree: false,
      chargebee: false,
      chargify: false,
      custom: false,
      naviga: false,
      openpay: true,
      ordergroove: false,
      paddle: false,
      recharge: false,
      recurly: false,
      stripe: false,
      submarine: false,
      zuora: false,
    },
  },
} satisfies Record<SegmentPropertyKey, SegmentConditionPropertyConfig>;

export type SegmentPropertyValues = {
  [key in SegmentPropertyKey]: (typeof propertyConfigs)[key]["type"] extends "boolean"
    ? BooleanValue
    : (typeof propertyConfigs)[key]["type"] extends "currency"
    ? NumberValue
    : (typeof propertyConfigs)[key]["type"] extends "date"
    ? DateValue
    : (typeof propertyConfigs)[key]["type"] extends "id"
    ? (typeof propertyConfigs)[key]["array"] extends true
      ? IdArrayValue
      : IdValue
    : (typeof propertyConfigs)[key]["type"] extends "location"
    ? LocationValue
    : (typeof propertyConfigs)[key]["type"] extends "number"
    ? (typeof propertyConfigs)[key]["array"] extends true
      ? NumberArrayValue
      : NumberValue
    : (typeof propertyConfigs)[key]["type"] extends "percentage"
    ? (typeof propertyConfigs)[key]["array"] extends true
      ? NumberArrayValue
      : NumberValue
    : (typeof propertyConfigs)[key]["type"] extends "select"
    ? (typeof propertyConfigs)[key]["array"] extends true
      ? SelectArrayValue
      : SelectValue
    : (typeof propertyConfigs)[key]["type"] extends "string"
    ? (typeof propertyConfigs)[key]["array"] extends true
      ? StringArrayValue
      : StringValue
    : (typeof propertyConfigs)[key]["type"] extends "timestamp"
    ? TimestampValue
    : never;
} & Record<"custom_properties", Record<string, unknown>>;

export type SubscriptionSegmentPropertyValues = {
  [K in SegmentPropertyKey as (typeof propertyConfigs)[K]["entity"] extends "subscription"
    ? K
    : never]: SegmentPropertyValues[K];
};

export type SubscriberSegmentPropertyValues = {
  [K in SegmentPropertyKey as (typeof propertyConfigs)[K]["entity"] extends "subscriber"
    ? K
    : never]: SegmentPropertyValues[K];
};

export type SubscriptionSegmentPropertyKey =
  keyof SubscriptionSegmentPropertyValues;

export type SubscriberSegmentPropertyKey =
  keyof SubscriberSegmentPropertyValues;

export type SubscriptionSegmentPropertyValueMapper<
  T extends SubscriptionSegmentPropertyKey
> = (
  subscription: SubscriptionSegmentPropertyValuesFragment
) => SegmentPropertyValues[T];

export type SubscriberSegmentPropertyValueMapper<
  T extends SubscriberSegmentPropertyKey
> = (
  subscriber: SubscriberSegmentPropertyValuesFragment
) => SegmentPropertyValues[T];

export const getPropertiesFilteredForPlatform = (platform: platform_enum) =>
  Object.values(propertyConfigs).filter(
    (config) => !!config.platforms[platform]
  );

export const getSegmentConditionPropertyOperators = (
  type: SegmentConditionPropertyType,
  array: boolean
): segment_condition_operator_enum[] => {
  switch (type) {
    case "currency":
    case "number":
    case "percentage":
      return [
        segment_condition_operator_enum.eq,
        segment_condition_operator_enum.neq,
        segment_condition_operator_enum.gt,
        segment_condition_operator_enum.lt,
        segment_condition_operator_enum.gte,
        segment_condition_operator_enum.lte,
      ];

    case "select":
      return [
        segment_condition_operator_enum.eq,
        segment_condition_operator_enum.neq,
      ];

    case "boolean":
      return [segment_condition_operator_enum.eq];

    case "id":
      return [
        segment_condition_operator_enum.eq,
        segment_condition_operator_enum.neq,
        segment_condition_operator_enum.contains,
        segment_condition_operator_enum.starts_with,
        segment_condition_operator_enum.ends_with,
        segment_condition_operator_enum.in,
        segment_condition_operator_enum.nin,
      ];

    case "string":
      const operators = [
        segment_condition_operator_enum.eq,
        segment_condition_operator_enum.neq,
        segment_condition_operator_enum.contains,
        segment_condition_operator_enum.starts_with,
        segment_condition_operator_enum.ends_with,
      ];
      if (!array) {
        operators.push(
          segment_condition_operator_enum.is_set,
          segment_condition_operator_enum.is_not_set
        );
      }

      return operators;

    case "date":
    case "timestamp":
      return [
        segment_condition_operator_enum.eq,
        segment_condition_operator_enum.neq,
        segment_condition_operator_enum.gt,
        segment_condition_operator_enum.lt,
        segment_condition_operator_enum.gte,
        segment_condition_operator_enum.lte,
        segment_condition_operator_enum.within_last_days,
        segment_condition_operator_enum.more_than_days_ago,
        segment_condition_operator_enum.within_next_days,
        segment_condition_operator_enum.more_than_days_from_now,
      ];

    case "location":
      return [
        segment_condition_operator_enum.eq,
        segment_condition_operator_enum.neq,
        segment_condition_operator_enum.in,
        segment_condition_operator_enum.nin,
      ];

    default:
      throw new UnreachableCaseError(type);
  }
};

export const getSegmentConditionCustomPropertyOperators = (
  type: `${property_type_enum}`
) => {
  switch (type) {
    case "text":
      return getSegmentConditionPropertyOperators("string", false);

    case "number":
      return getSegmentConditionPropertyOperators("number", false);

    case "boolean":
      return getSegmentConditionPropertyOperators("boolean", false);

    case "date":
    case "timestamp":
      return [
        segment_condition_operator_enum.eq,
        segment_condition_operator_enum.neq,
        segment_condition_operator_enum.gt,
        segment_condition_operator_enum.lt,
        segment_condition_operator_enum.gte,
        segment_condition_operator_enum.lte,
        segment_condition_operator_enum.within_last_days,
        segment_condition_operator_enum.more_than_days_ago,
        segment_condition_operator_enum.within_next_days,
        segment_condition_operator_enum.more_than_days_from_now,
      ];

    default:
      throw new UnreachableCaseError(type);
  }
};

export const getDefaultValue = (condition: SegmentConditionFragment) => {
  if (condition.property === segment_condition_property_enum.custom_property) {
    return "";
  }

  const property = propertyConfigs[condition.property];
  const type = property.type;

  switch (type) {
    case "string":
    case "currency":
    case "number":
    case "id":
    case "percentage":
      return "";

    case "boolean":
      return "true";

    case "select":
      if (!property?.options?.length) {
        throw new Error("No filed options");
      }
      return property.options[0]?.id;

    case "date":
    case "location":
      return undefined;

    default:
      throw new UnreachableCaseError(type);
  }
};

export const getConditionValue = (condition: SegmentConditionFragment) => {
  if (condition.property === segment_condition_property_enum.custom_property) {
    if (typeof condition.value === "boolean") {
      return JSON.stringify(condition.value);
    }

    return condition.value;
  }

  const property = propertyConfigs[condition.property];

  if (condition.operator === "in" || condition.operator === "nin") {
    return JSON.stringify(condition.value);
  }

  if (property?.type === "boolean") {
    return JSON.stringify(condition.value);
  }

  try {
    return JSON.parse(condition.value);
  } catch (e) {
    return condition.value;
  }
};

export const getConditionValueForInsert = (
  condition: SegmentConditionFragment
) => {
  if (condition.property === segment_condition_property_enum.custom_property) {
    if (!condition.custom_property) {
      throw new Error("Invalid property");
    }

    switch (condition.custom_property.type) {
      case "number":
        if (typeof condition.value === "number") {
          return condition.value;
        }

        return Number(condition.value.replace(/[^0-9.]/g, ""));

      case "boolean":
        return condition.value === "true";

      default:
        return condition.value.trim();
    }
  }

  const property = propertyConfigs[condition.property];

  switch (property?.type) {
    case "currency":
    case "number":
    case "percentage":
      return Number(condition.value.toString().replace(/[^0-9.]/g, ""));

    case "boolean":
      if (typeof condition.value === "boolean") {
        return condition.value;
      }

      return condition.value === "true";

    case "location":
      return typeof condition.value === "string"
        ? JSON.parse(condition.value)
        : condition.value;

    // String
    default:
      if (condition.operator === "in" || condition.operator === "nin") {
        if (
          typeof condition.value === "object" &&
          Array.isArray(condition.value)
        ) {
          return condition.value;
        }

        return JSON.parse(condition.value);
      }

      return condition.value.trim();
  }
};

export const getFriendlyValue = (
  property: segment_condition_property_enum,
  value: any,
  isArray: boolean,
  customProperty?: SegmentConditionFragment["custom_property"]
): string => {
  if (property === segment_condition_property_enum.custom_property) {
    if (!customProperty) {
      throw new Error("Invalid property");
    }

    switch (customProperty.type) {
      case "boolean":
        return value ? "True" : "False";

      case "number":
        switch (customProperty.format) {
          case "number":
            return Number(value).toLocaleString();

          case "currency":
            return formatCurrency(value);

          default:
            return value;
        }

      default:
        return value;
    }
  }

  const propertyConfig = propertyConfigs[property];

  const formatValue = (unformattedValue: any) => {
    switch (propertyConfig?.type) {
      case "currency":
        return formatCurrency(unformattedValue);

      case "percentage":
        return `${Number(unformattedValue).toLocaleString()}%`;

      case "select":
        return (
          propertyConfig?.options?.find(
            (option) => option.id === unformattedValue
          )?.label || ""
        );

      case "boolean":
        return unformattedValue ? "True" : "False";

      case "location":
        const value =
          typeof unformattedValue === "string"
            ? JSON.parse(unformattedValue)
            : unformattedValue;

        if (value.state) {
          return `${states[value.country][value.state]}, ${
            countries[value.country]
          }`;
        }

        return `${countries[value.country]}`;

      case "date":
        try {
          return Temporal.PlainDate.from(unformattedValue).toLocaleString(
            undefined,
            {
              dateStyle: "long",
            }
          );
        } catch (e) {
          return "";
        }
    }

    return unformattedValue;
  };

  if (isArray) {
    return value
      .map(formatValue)
      .join(propertyConfig?.type === "location" ? "; " : ", ");
  }

  return formatValue(value);
};

export const getWhereClause = <T>(
  property: [keyof T][keyof T extends any ? 0 : never],
  operator: `${segment_condition_operator_enum}`,
  value: any
) => {
  switch (operator) {
    case "eq":
      return {
        [property]: { _eq: value },
      };

    case "neq":
      return {
        _or: [
          {
            [property]: { _neq: value },
          },
          {
            [property]: { _is_null: true },
          },
        ],
      };

    case "gt":
      return {
        [property]: { _gt: value },
      };

    case "lt":
      return {
        [property]: { _lt: value },
      };

    case "gte":
      return {
        [property]: { _gte: value },
      };

    case "lte":
      return {
        [property]: { _lte: value },
      };

    case "contains":
      return {
        [property]: { _ilike: "%" + value + "%" },
      };

    case "starts_with":
      return {
        [property]: { _ilike: value + "%" },
      };

    case "ends_with":
      return {
        [property]: { _ilike: "%" + value },
      };

    case "in":
      return {
        [property]: { _in: value },
      };

    case "nin":
      return {
        _or: [
          {
            [property]: {
              _nin: value,
            },
          },
          {
            [property]: { _is_null: true },
          },
        ],
      };

    case "is_set":
      return {
        _and: [
          {
            [property]: { _is_null: false },
          },
          {
            [property]: { _neq: "" },
          },
        ],
      };

    case "is_not_set":
      return {
        _or: [
          {
            [property]: { _is_null: true },
          },
          {
            [property]: { _eq: "" },
          },
        ],
      };

    case "within_last_days":
      return {
        _and: [
          {
            [property]: { _lte: Temporal.Now.plainDateISO().toString() },
          },
          {
            [property]: {
              _gte: Temporal.Now.plainDateISO()
                .subtract({ days: value })
                .toString(),
            },
          },
        ],
      };

    case "more_than_days_ago":
      return {
        [property]: {
          _lt: Temporal.Now.plainDateISO().subtract({ days: value }).toString(),
        },
      };

    case "within_next_days":
      return {
        _and: [
          {
            [property]: { _gte: Temporal.Now.plainDateISO().toString() },
          },
          {
            [property]: {
              _lte: Temporal.Now.plainDateISO().add({ days: value }).toString(),
            },
          },
        ],
      };

    case "more_than_days_from_now":
      return {
        [property]: {
          _gt: Temporal.Now.plainDateISO().add({ days: value }).toString(),
        },
      };

    default:
      throw new UnreachableCaseError(operator);
  }
};
