import gql from "graphql-tag";

export default gql`
  fragment FlowSubscriptionFragment on subscription {
    id
    platform_subscription {
      platform_id
      swappable_ecommerce_products {
        platform_ecommerce_product {
          platform_id
          name
          image_url
          platform_variants {
            platform_id
            name
            price
            currency
            platform_variant_options {
              key
              value
            }
          }
          platform_plan_ecommerce_products(
            where: { primary: { _eq: true } }
            limit: 1
          ) {
            discount_amount
            discount_type
          }
        }
      }
      platform_subscription_plans {
        price
        platform_variant {
          platform_id
          price
          currency
          platform_ecommerce_product {
            platform_id
            name
          }
          platform_variant_options {
            key
            value
          }
        }
      }
    }
  }
`;
