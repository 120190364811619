import { useMutation, useQuery } from "@apollo/client";
import { faChevronDown, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import gql from "graphql-tag";
import { cloneDeep, sortBy } from "lodash";
import { useCallback, useEffect, useState } from "react";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useToasts } from "react-toast-notifications";
import { isPresent } from "ts-is-present";
import tw, { css } from "twin.macro";

import {
  AppPropertyFragment,
  CreateSegmentPanelMutation,
  CreateSegmentPanelMutationVariables,
  CreateSegmentPanelQuery,
  platform_enum,
  PrimarySegmentConditionGroupFragment,
  property_entity_enum,
  segment_condition_boolean_operator_enum,
  segment_condition_operator_enum,
  segment_condition_property_enum,
  SegmentConditionFragment,
  SegmentFragment,
  SegmentPanelCreateGroupMutation,
  SegmentPanelCreateGroupMutationVariables,
  SegmentPanelUpdateMutation,
  SegmentPanelUpdateMutationVariables,
} from "../../__generated__/graphql";
import Callout from "../../common/Callout";
import DropdownMenu from "../../common/DropdownMenu";
import Button from "../../common/form/Button";
import FieldError from "../../common/form/FieldError";
import FieldInput from "../../common/form/FieldInput";
import FieldLabel from "../../common/form/FieldLabel";
import FieldRow from "../../common/form/FieldRow";
import FormMode from "../../common/form/FormMode";
import TagsInput from "../../common/form/input/TagsInput";
import TextInput from "../../common/form/input/TextInput";
import TheAppPropertyFragment from "../../common/fragments/AppPropertyFragment";
import TheAppSegmentFragment from "../../common/fragments/AppSegmentFragment";
import useUpdateTags from "../../common/mutations/useUpdateTags";
import Panel, { PanelProps } from "../../common/panel/Panel";
import PanelButtons from "../../common/panel/PanelButtons";
import PanelFormBody from "../../common/panel/PanelFormBody";
import PanelTitle from "../../common/panel/PanelTitle";
import {
  getConditionValueForInsert,
  getDefaultValue,
  getPropertiesFilteredForPlatform,
  propertyConfigs,
  SegmentConditionPropertyConfig,
} from "../../common/segments/lib";
import useTags from "../../common/tags/useTags";
import { TRACK_EVENT_SEGMENT_CREATED } from "../../common/track-events/events";
import useFocusFirstEmptyInput from "../../common/useFocusFirstEmptyInput";
import useTrackEvent from "../../common/useTrackEvent";
import groupSegmentProperties from "./lib/groupSegmentProperties";
import SegmentCondition from "./SegmentCondition";

const SegmentConditions = tw.div`pb-4`;

const AddCondition = tw.div`mt-3`;

const BooleanDivider: React.FunctionComponent<{ operator: string }> = ({
  operator,
}) => (
  <div tw="flex justify-center border-t border-divider border-dashed relative mt-1 mb-2">
    <div
      tw="font-semibold text-type-light bg-white absolute px-2 uppercase"
      css={css`
        top: -13px;
      `}
    >
      {operator}
    </div>
  </div>
);

const BooleanOperatorDropdown: React.FunctionComponent<{
  value: segment_condition_boolean_operator_enum;
  onChange: (operator: segment_condition_boolean_operator_enum) => void;
}> = ({ value, onChange }) => (
  <DropdownMenu
    items={[
      {
        value: "and",
        label: "Match all (AND)",
        isActive: value === "and",
        onClick: () => onChange(segment_condition_boolean_operator_enum.and),
      },
      {
        value: "or",
        label: "Match any (OR)",
        isActive: value === "or",
        onClick: () => onChange(segment_condition_boolean_operator_enum.or),
      },
    ]}
    render={({ onClick }) => (
      <div
        tw="bg-gray-50 border border-t-0 border-divider rounded-b px-2 text-type-light hover:bg-gray-100 hover:cursor-pointer transition-all"
        css={css`
          fieldset:disabled & {
            ${tw`opacity-50 pointer-events-none`}
          }
        `}
        onClick={onClick}
      >
        Match {value === "or" ? "any" : "all"} (
        <span tw="uppercase">{value}</span>)
        <span tw="ml-2">
          <FontAwesomeIcon icon={faChevronDown} transform="shrink-5" />
        </span>
      </div>
    )}
    zIndex={50}
  />
);

const TheCreateSegmentPanelQuery = gql`
  query CreateSegmentPanelQuery {
    segment {
      ...AppSegmentFragment
    }
    property(where: { deleted_at: { _is_null: true } }) {
      ...AppPropertyFragment
    }
  }
  ${TheAppSegmentFragment}
  ${TheAppPropertyFragment}
`;

interface FormValues {
  name: string;
  tags: string[];
  group: string;
}

type CreateSegmentPanelProps = PanelProps & {
  onClose: (segment: SegmentFragment | null) => void;
  platform: platform_enum;
  onClickCreateProperty: (entity: property_entity_enum) => void;
  newProperty?: AppPropertyFragment;
  mode: FormMode;
  segment?: SegmentFragment;
  inUseByFlow?: boolean;
  hasHistoricalData?: boolean;
  top?: number;
};

const getDefaultGroup = (
  properties: SegmentConditionPropertyConfig[],
  conditionId = 1
) => {
  const condition = getDefaultCondition(properties);
  const group: PrimarySegmentConditionGroupFragment = {
    __typename: "segment_condition_group",
    id: 1,
    boolean_operator: segment_condition_boolean_operator_enum.and,
    segment_condition_group_entries: [
      {
        __typename: "segment_condition_group_entry",
        entry_segment_condition: {
          ...condition,
          id: conditionId,
        },
      },
    ],
  };
  return group;
};

const getDefaultCondition = (properties: SegmentConditionPropertyConfig[]) => {
  const property = sortBy(properties, "name")[0];

  const condition: SegmentConditionFragment = {
    __typename: "segment_condition",
    id: 1,
    property: property.id,
    operator: segment_condition_operator_enum.eq,
    value: "",
  };
  condition.value = getDefaultValue(condition);
  return condition;
};

const findCondition = (
  group: PrimarySegmentConditionGroupFragment,
  conditionId: number
) => {
  const condition = (() => {
    for (const entry of group.segment_condition_group_entries) {
      if (entry.entry_segment_condition?.id === conditionId) {
        return entry.entry_segment_condition;
      }

      if (entry.entry_segment_condition_group) {
        for (const groupEntry of entry.entry_segment_condition_group
          .segment_condition_group_entries) {
          if (groupEntry.entry_segment_condition?.id === conditionId) {
            return groupEntry.entry_segment_condition;
          }
        }
      }
    }
  })();

  if (!condition) {
    throw new Error(`Condition ${conditionId} not found`);
  }

  return condition;
};

const CreateSegmentPanel: React.FunctionComponent<CreateSegmentPanelProps> = ({
  mode,
  onClose,
  platform,
  onClickCreateProperty,
  newProperty,
  segment,
  inUseByFlow = false,
  hasHistoricalData = false,
  top = 0,
  ...props
}) => {
  const filteredProperties = getPropertiesFilteredForPlatform(platform);
  const defaultGroup = getDefaultGroup(filteredProperties);
  const defaultCondition = getDefaultCondition(filteredProperties);
  const groupedProperties = groupSegmentProperties(filteredProperties);
  const { addToast } = useToasts();
  const trackEvent = useTrackEvent();

  const { tags } = useTags();
  const updateTags = useUpdateTags();

  const { register, handleSubmit, formState, reset, control } =
    useForm<FormValues>({
      defaultValues: {
        name: segment?.name || "",
        tags: (segment?.segment_tags || [])
          .map((t) => t.tag)
          .filter(isPresent)
          .map((t) => t.name),
        group: segment
          ? JSON.stringify(segment.primary_segment_condition_group)
          : JSON.stringify(defaultGroup),
      },
    });
  const [formRef, setFormRef] = useState<HTMLFormElement | null>(null);
  const [submitting, setSubmitting] = useState(false);
  useFocusFirstEmptyInput(formRef);
  const [group, setGroup] = useState<PrimarySegmentConditionGroupFragment>(
    segment?.primary_segment_condition_group
      ? segment.primary_segment_condition_group
      : defaultGroup
  );
  const [createPropertyConditionId, setCreatePropertyConditionId] =
    useState<number>();
  const [newPropertyIds, setNewPropertyIds] = useState<number[]>([]);

  const {
    data: existingSegmentsData,
    loading: existingSegmentsLoading,
    refetch: refetchExistingSegments,
  } = useQuery<CreateSegmentPanelQuery>(TheCreateSegmentPanelQuery);

  useEffect(() => {
    if (props.isOpen) {
      refetchExistingSegments();
    }
  }, [props.isOpen, refetchExistingSegments]);

  const [createSegment] = useMutation<
    CreateSegmentPanelMutation,
    CreateSegmentPanelMutationVariables
  >(
    gql`
      mutation CreateSegmentPanelMutation($input: segment_insert_input!) {
        insert_segment_one(object: $input) {
          ...AppSegmentFragment
        }
      }
      ${TheAppSegmentFragment}
    `,
    {
      update: (cache, { data: newData }) => {
        if (!newData?.insert_segment_one) {
          return;
        }

        const result = cache.readQuery<CreateSegmentPanelQuery>({
          query: TheCreateSegmentPanelQuery,
        });

        if (!result?.segment) {
          return;
        }

        cache.writeQuery<CreateSegmentPanelQuery>({
          query: TheCreateSegmentPanelQuery,
          data: {
            segment: [...result.segment, newData.insert_segment_one],
            property: [...(existingSegmentsData?.property || [])],
          },
        });
      },
    }
  );

  const [createGroup] = useMutation<
    SegmentPanelCreateGroupMutation,
    SegmentPanelCreateGroupMutationVariables
  >(gql`
    mutation SegmentPanelCreateGroupMutation(
      $group: segment_condition_group_insert_input!
    ) {
      insert_segment_condition_group_one(object: $group) {
        id
      }
    }
  `);

  const [updateSegment] = useMutation<
    SegmentPanelUpdateMutation,
    SegmentPanelUpdateMutationVariables
  >(gql`
    mutation SegmentPanelUpdateMutation(
      $segmentId: Int!
      $name: String!
      $tagIds: jsonb!
      $groupId: Int!
    ) {
      update_segment_by_pk(
        pk_columns: { id: $segmentId }
        _set: {
          name: $name
          tag_ids: $tagIds
          primary_segment_condition_group_id: $groupId
        }
      ) {
        ...AppSegmentFragment
      }
    }
    ${TheAppSegmentFragment}
  `);

  const groupInput = () => ({
    boolean_operator: group.boolean_operator,
    segment_condition_group_entries: {
      data: group.segment_condition_group_entries.map((e, i) => ({
        position: i,
        entry_segment_condition_group: e.entry_segment_condition_group
          ? {
              data: {
                boolean_operator:
                  e.entry_segment_condition_group.boolean_operator,
                segment_condition_group_entries: {
                  data: e.entry_segment_condition_group.segment_condition_group_entries.map(
                    (ge, ei) => ({
                      position: ei,
                      entry_segment_condition: ge.entry_segment_condition
                        ? {
                            data: {
                              property: ge.entry_segment_condition.property,
                              operator: ge.entry_segment_condition.operator,
                              value: getConditionValueForInsert(
                                ge.entry_segment_condition
                              ),
                              property_id:
                                ge.entry_segment_condition.property_id,
                            },
                          }
                        : null,
                    })
                  ),
                },
              },
            }
          : null,
        entry_segment_condition: e.entry_segment_condition
          ? {
              data: {
                property: e.entry_segment_condition.property,
                operator: e.entry_segment_condition.operator,
                value: getConditionValueForInsert(e.entry_segment_condition),
                property_id: e.entry_segment_condition.property_id,
              },
            }
          : null,
      })),
    },
  });

  const onSubmit = handleSubmit(async ({ name, tags }) => {
    setSubmitting(true);

    const tagIds = await updateTags(tags);

    if (mode === "create") {
      const result = await createSegment({
        variables: {
          input: {
            name: name.trim(),
            tag_ids: tagIds,
            primary_segment_condition_group: {
              data: groupInput(),
            },
          },
        },
      });

      if (result.data?.insert_segment_one?.id) {
        await trackEvent(TRACK_EVENT_SEGMENT_CREATED, {
          segment_id: result.data.insert_segment_one.token,
        });

        onClose(result.data.insert_segment_one);
        reset();
      } else {
        setSubmitting(false);
        addToast(<div>Oops! The segment couldn't be created.</div>, {
          appearance: "error",
        });
      }
    } else {
      if (!segment) {
        throw new Error();
      }

      const newGroupResult = await createGroup({
        variables: {
          group: groupInput(),
        },
      });

      if (!newGroupResult.data?.insert_segment_condition_group_one) {
        throw new Error();
      }

      const result = await updateSegment({
        variables: {
          segmentId: segment.id,
          name: name.trim(),
          tagIds: tagIds,
          groupId: newGroupResult.data.insert_segment_condition_group_one.id,
        },
      });

      if (result.data?.update_segment_by_pk?.id) {
        onClose(result.data.update_segment_by_pk);
        reset();
      } else {
        setSubmitting(false);
        addToast(<div>Oops! The segment couldn't be updated.</div>, {
          appearance: "error",
        });
      }
    }
  });

  const onPropertyChange = useCallback(
    (
      conditionId: number,
      property: segment_condition_property_enum | number
    ) => {
      const groupCopy = cloneDeep(group);

      const condition = findCondition(groupCopy, conditionId);

      condition.property =
        typeof property === "number"
          ? segment_condition_property_enum.custom_property
          : property;
      condition.property_id = typeof property === "number" ? property : null;
      condition.operator = segment_condition_operator_enum.eq;
      condition.value = getDefaultValue(condition);

      condition.custom_property = null;
      if (condition.property_id) {
        const customProperty = existingSegmentsData?.property.find(
          (p) => p.id === condition.property_id
        );
        if (!customProperty) {
          throw new Error(`Property not found: ${condition.property_id}`);
        }
        condition.custom_property = customProperty;
      }

      setGroup(groupCopy);
    },
    [existingSegmentsData?.property, group]
  );

  const onOperatorChange = (
    conditionId: number,
    operator: segment_condition_operator_enum
  ) => {
    const groupCopy = cloneDeep(group);

    const condition = findCondition(groupCopy, conditionId);

    if (!condition) {
      throw new Error(`Condition ${conditionId} not found`);
    }

    if (
      (condition.operator === "in" || condition.operator === "nin") &&
      operator !== "in" &&
      operator !== "nin"
    ) {
      condition.value = "";
    }

    if (
      !["eq", "neq", "in", "nin"].includes(condition.operator) &&
      ["in", "nin"].includes(operator)
    ) {
      condition.value = undefined;
    }

    condition.operator = operator;

    setGroup(groupCopy);
  };

  const onValueChange = (conditionId: number, value: any) => {
    const groupCopy = cloneDeep(group);

    const condition = findCondition(groupCopy, conditionId);

    if (!condition) {
      return;
    }

    condition.value = value;

    setGroup(groupCopy);
  };

  const onBooleanOperatorChange = (
    groupId: number,
    operator: segment_condition_boolean_operator_enum
  ) => {
    const groupCopy = cloneDeep(group);

    if (groupCopy.id === groupId) {
      groupCopy.boolean_operator = operator;
    } else {
      for (const entry of groupCopy.segment_condition_group_entries) {
        if (entry.entry_segment_condition_group?.id === groupId) {
          entry.entry_segment_condition_group.boolean_operator = operator;
        }
      }
    }

    setGroup(groupCopy);
  };

  const addCondition = (groupId: number) => {
    const groupCopy = cloneDeep(group);

    if (groupCopy.id === groupId) {
      groupCopy.segment_condition_group_entries.push({
        __typename: "segment_condition_group_entry",
        entry_segment_condition: { ...defaultCondition, id: nextConditionId() },
      });
    } else {
      for (const entry of groupCopy.segment_condition_group_entries) {
        if (entry.entry_segment_condition_group?.id === groupId) {
          entry.entry_segment_condition_group.segment_condition_group_entries.push(
            {
              __typename: "segment_condition_group_entry",
              entry_segment_condition: {
                ...defaultCondition,
                id: nextConditionId(),
              },
            }
          );
        }
      }
    }

    setGroup(groupCopy);
  };

  const nextGroupId = () => {
    let maxId = 0;

    if (group.id > maxId) {
      maxId = group.id;
    }

    for (const entry of group.segment_condition_group_entries) {
      if (
        entry.entry_segment_condition_group &&
        entry.entry_segment_condition_group.id > maxId
      ) {
        maxId = entry.entry_segment_condition_group.id;
      }
    }

    return maxId + 1;
  };

  const nextConditionId = () => {
    let maxId = 0;

    for (const entry of group.segment_condition_group_entries) {
      if (
        entry.entry_segment_condition &&
        entry.entry_segment_condition.id > maxId
      ) {
        maxId = entry.entry_segment_condition.id;
      }

      if (entry.entry_segment_condition_group) {
        for (const subEntry of entry.entry_segment_condition_group
          .segment_condition_group_entries) {
          if (
            subEntry.entry_segment_condition &&
            subEntry.entry_segment_condition.id > maxId
          ) {
            maxId = subEntry.entry_segment_condition.id;
          }
        }
      }
    }

    return maxId + 1;
  };

  const addGroup = () => {
    const groupCopy = cloneDeep(group);

    const defaultGroup = getDefaultGroup(filteredProperties, nextConditionId());

    groupCopy.segment_condition_group_entries.push({
      __typename: "segment_condition_group_entry",
      entry_segment_condition_group: { ...defaultGroup, id: nextGroupId() },
    });

    setGroup(groupCopy);
  };

  const deleteCondition = (conditionId: number) => {
    const groupCopy = cloneDeep(group);

    groupCopy.segment_condition_group_entries =
      groupCopy.segment_condition_group_entries.filter(
        (e) => e.entry_segment_condition?.id !== conditionId
      );

    for (const entry of groupCopy.segment_condition_group_entries) {
      if (entry.entry_segment_condition_group) {
        entry.entry_segment_condition_group.segment_condition_group_entries =
          entry.entry_segment_condition_group.segment_condition_group_entries.filter(
            (e) => e.entry_segment_condition?.id !== conditionId
          );
      }
    }

    setGroup(groupCopy);
  };

  const deleteGroup = (groupId: number) => {
    const groupCopy = cloneDeep(group);

    groupCopy.segment_condition_group_entries =
      groupCopy.segment_condition_group_entries.filter(
        (e) => e.entry_segment_condition_group?.id !== groupId
      );

    setGroup(groupCopy);
  };

  useEffect(() => {
    if (
      typeof createPropertyConditionId === "undefined" ||
      !newProperty ||
      newPropertyIds.includes(newProperty.id)
    ) {
      return;
    }

    const property = existingSegmentsData?.property.find(
      (p) => p.id === newProperty.id
    );
    if (!property) {
      return;
    }

    const condition = findCondition(group, createPropertyConditionId);
    if (condition.property_id === newProperty.id) {
      return;
    }

    setCreatePropertyConditionId(undefined);
    setNewPropertyIds([...newPropertyIds, newProperty.id]);
    onPropertyChange(createPropertyConditionId, newProperty.id);
  }, [
    createPropertyConditionId,
    existingSegmentsData?.property,
    group,
    newProperty,
    newPropertyIds,
    onPropertyChange,
  ]);

  return (
    <Panel
      width={650}
      top={top}
      {...props}
      header={
        <>
          <PanelTitle>
            {mode === "create" ? <>Create segment</> : <>Edit segment</>}
          </PanelTitle>
          <PanelButtons>
            <Button
              buttonType="primary"
              form="create-segment"
              isLoading={submitting}
              disabled={submitting}
            >
              Save
            </Button>
            <Button
              type="button"
              buttonType="default"
              onClick={() => onClose(null)}
              disabled={submitting}
            >
              Cancel
            </Button>
          </PanelButtons>
        </>
      }
      isLoading={existingSegmentsLoading}
    >
      <PanelFormBody>
        {mode === "edit" && inUseByFlow && (
          <div tw="mt-3 mb-4">
            <Callout
              type="warning"
              heading="Segment is in use by a cancellation flow"
            >
              Changing the segment conditions may affect your live cancellation
              flow.
            </Callout>
          </div>
        )}
        {mode === "edit" && hasHistoricalData && (
          <div css={inUseByFlow ? tw`mb-4` : tw`mt-3 mb-4`}>
            <Callout type="neutral" heading="Segment has historical data">
              Changing the segment conditions may affect your historical data
              and reporting.
            </Callout>
          </div>
        )}

        <form
          id="create-segment"
          onSubmit={onSubmit}
          ref={(ref) => setFormRef(ref)}
        >
          <fieldset disabled={submitting}>
            <FieldRow>
              <FieldLabel>
                <label htmlFor="name">Name</label>
              </FieldLabel>
              <FieldInput>
                <TextInput
                  {...register("name", {
                    required: true,
                    validate: (value) => {
                      if (
                        value.trim() !== "" &&
                        existingSegmentsData?.segment.find(
                          (s) => s.id !== segment?.id && s.name === value
                        )
                      ) {
                        return "A segment with that name already exists.";
                      }
                    },
                  })}
                  id="name"
                  width="full"
                  fieldError={formState.errors.name}
                />
                <FieldError error={formState.errors.name} />
              </FieldInput>
            </FieldRow>
            <FieldRow>
              <FieldLabel>
                <label>Tags</label>
              </FieldLabel>
              <FieldInput>
                <Controller
                  control={control}
                  name="tags"
                  render={({ field }) => (
                    <TagsInput
                      tags={tags}
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />
              </FieldInput>
            </FieldRow>
            <FieldRow style={{ display: "block", borderBottomWidth: 0 }}>
              <FieldLabel>Conditions</FieldLabel>
              <SegmentConditions>
                <div tw="border-t border-divider flex justify-center mb-2">
                  <BooleanOperatorDropdown
                    value={group.boolean_operator}
                    onChange={(operator) =>
                      onBooleanOperatorChange(group.id, operator)
                    }
                  />
                </div>

                {group.segment_condition_group_entries.map(
                  (entry, groupIndex) => (
                    <div key={`group-${groupIndex}`}>
                      {entry.entry_segment_condition ? (
                        <SegmentCondition
                          index={groupIndex}
                          condition={entry.entry_segment_condition}
                          properties={existingSegmentsData?.property || []}
                          groupedProperties={groupedProperties}
                          canDelete={
                            group.segment_condition_group_entries.filter(
                              (e) => !!e.entry_segment_condition
                            ).length > 1
                          }
                          onPropertyChange={(value) =>
                            onPropertyChange(
                              entry.entry_segment_condition!.id,
                              value
                            )
                          }
                          onOperatorChange={(value) =>
                            onOperatorChange(
                              entry.entry_segment_condition!.id,
                              value as segment_condition_operator_enum
                            )
                          }
                          onValueChange={(value) =>
                            onValueChange(
                              entry.entry_segment_condition!.id,
                              value
                            )
                          }
                          onDelete={() =>
                            deleteCondition(entry.entry_segment_condition!.id)
                          }
                          onClickCreateProperty={(entity) => {
                            setCreatePropertyConditionId(
                              entry.entry_segment_condition!.id
                            );
                            onClickCreateProperty(entity);
                          }}
                        />
                      ) : entry.entry_segment_condition_group ? (
                        <div tw="border border-divider mt-6 mb-6 p-4 px-4 pt-0 rounded-lg relative">
                          <button
                            type="button"
                            tw="absolute bg-white! text-type-light border border-divider rounded-full flex items-center justify-center hover:bg-gray-50! transition-all"
                            css={css`
                              top: -6px;
                              left: -6px;
                              width: 26px;
                              height: 26px;

                              fieldset:disabled & {
                                ${tw`opacity-50 pointer-events-none`}
                              }
                            `}
                            onClick={() =>
                              deleteGroup(
                                entry.entry_segment_condition_group!.id
                              )
                            }
                          >
                            <FontAwesomeIcon icon={faTimes} />
                          </button>
                          <div tw="flex justify-center mb-4">
                            <BooleanOperatorDropdown
                              value={
                                entry.entry_segment_condition_group
                                  .boolean_operator
                              }
                              onChange={(operator) =>
                                onBooleanOperatorChange(
                                  entry.entry_segment_condition_group!.id,
                                  operator
                                )
                              }
                            />
                          </div>
                          {entry.entry_segment_condition_group.segment_condition_group_entries.map(
                            (groupEntry, entryIndex) => (
                              <div
                                key={`${groupIndex}-condition-${entryIndex}`}
                              >
                                {groupEntry.entry_segment_condition && (
                                  <SegmentCondition
                                    index={entryIndex}
                                    condition={
                                      groupEntry.entry_segment_condition
                                    }
                                    properties={
                                      existingSegmentsData?.property || []
                                    }
                                    groupedProperties={groupedProperties}
                                    canDelete={
                                      entry.entry_segment_condition_group!
                                        .segment_condition_group_entries
                                        .length > 1
                                    }
                                    onPropertyChange={(value) =>
                                      onPropertyChange(
                                        groupEntry.entry_segment_condition!.id,
                                        value
                                      )
                                    }
                                    onOperatorChange={(value) =>
                                      onOperatorChange(
                                        groupEntry.entry_segment_condition!.id,
                                        value as segment_condition_operator_enum
                                      )
                                    }
                                    onValueChange={(value) =>
                                      onValueChange(
                                        groupEntry.entry_segment_condition!.id,
                                        value
                                      )
                                    }
                                    onDelete={() =>
                                      deleteCondition(
                                        groupEntry.entry_segment_condition!.id
                                      )
                                    }
                                    onClickCreateProperty={(entity) => {
                                      setCreatePropertyConditionId(
                                        groupEntry.entry_segment_condition!.id
                                      );
                                      onClickCreateProperty(entity);
                                    }}
                                  />
                                )}
                                {entryIndex !==
                                  (
                                    entry.entry_segment_condition_group
                                      ?.segment_condition_group_entries || []
                                  ).length -
                                    1 && (
                                  <BooleanDivider
                                    operator={
                                      entry.entry_segment_condition_group
                                        ?.boolean_operator || ""
                                    }
                                  />
                                )}
                              </div>
                            )
                          )}
                          <div tw="border-t border-divider border-dashed">
                            <AddCondition>
                              <Button
                                type="button"
                                buttonType="alternate-secondary"
                                size="sm"
                                onClick={() =>
                                  addCondition(
                                    entry.entry_segment_condition_group!.id
                                  )
                                }
                              >
                                + Add condition
                              </Button>
                            </AddCondition>
                          </div>
                        </div>
                      ) : null}

                      {groupIndex !==
                        group.segment_condition_group_entries.length - 1 && (
                        <BooleanDivider operator={group.boolean_operator} />
                      )}
                    </div>
                  )
                )}

                <div tw="border-t border-divider border-dashed pt-4 mt-2">
                  <Button
                    type="button"
                    buttonType="alternate-secondary"
                    size="sm"
                    onClick={() => addCondition(group.id)}
                  >
                    + Add condition
                  </Button>
                  <Button
                    type="button"
                    buttonType="alternate-secondary"
                    size="sm"
                    tw="ml-1"
                    onClick={addGroup}
                  >
                    + Add group
                  </Button>
                </div>

                <input
                  {...register("group", {
                    validate: () => {
                      if (
                        group.segment_condition_group_entries.some(
                          (entry) =>
                            !!entry.entry_segment_condition &&
                            entry.entry_segment_condition.operator !==
                              "is_set" &&
                            entry.entry_segment_condition.operator !==
                              "is_not_set" &&
                            !entry.entry_segment_condition?.value?.toString()
                              .length
                        )
                      ) {
                        return "All conditions must have a value.";
                      }

                      for (const entry of group.segment_condition_group_entries) {
                        if (entry.entry_segment_condition) {
                          const property =
                            entry.entry_segment_condition.property !==
                            "custom_property"
                              ? propertyConfigs[
                                  entry.entry_segment_condition.property
                                ]
                              : undefined;
                          if (property?.type === "number") {
                            if (isNaN(entry.entry_segment_condition.value)) {
                              return `${property.name} must be a valid number.`;
                            } else if (
                              Number(entry.entry_segment_condition.value) < 0
                            ) {
                              return `${property.name} must be zero or greater.`;
                            }
                          }
                        }

                        if (entry.entry_segment_condition_group) {
                          if (
                            entry.entry_segment_condition_group.segment_condition_group_entries.some(
                              (groupEntry) =>
                                groupEntry.entry_segment_condition?.operator !==
                                  "is_set" &&
                                groupEntry.entry_segment_condition?.operator !==
                                  "is_not_set" &&
                                !groupEntry.entry_segment_condition?.value?.toString()
                                  .length
                            )
                          ) {
                            return "All conditions must have a value.";
                          }

                          for (const groupEntry of entry
                            .entry_segment_condition_group
                            .segment_condition_group_entries) {
                            if (groupEntry.entry_segment_condition) {
                              const property =
                                groupEntry.entry_segment_condition.property !==
                                "custom_property"
                                  ? propertyConfigs[
                                      groupEntry.entry_segment_condition
                                        .property
                                    ]
                                  : undefined;
                              if (property?.type === "number") {
                                if (
                                  isNaN(
                                    groupEntry.entry_segment_condition.value
                                  )
                                ) {
                                  return `${property.name} must be a valid number.`;
                                } else if (
                                  Number(
                                    groupEntry.entry_segment_condition.value
                                  ) < 0
                                ) {
                                  return `${property.name} must be zero or greater.`;
                                }
                              }
                            }
                          }
                        }
                      }
                    },
                  })}
                  type="hidden"
                  id="conditions"
                />

                <FieldError error={formState.errors.group} />
              </SegmentConditions>
            </FieldRow>
          </fieldset>
        </form>
      </PanelFormBody>
    </Panel>
  );
};

export default CreateSegmentPanel;
