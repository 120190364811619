import gql from "graphql-tag";

import TranslationFragment from "./TranslationFragment";

export default gql`
  fragment OfferDetailsFragment on offer {
    id
    token
    name
    override_active_offer
    show_banner
    type
    goal
    style
    image_url
    metadata
    confirmation_enabled
    created_at
    archived_at
    headline_translation {
      ...TranslationFragment
    }
    content_translation {
      ...TranslationFragment
    }
    button_text_translation {
      ...TranslationFragment
    }
    confirmation_content_translation {
      ...TranslationFragment
    }
    confirmation_confirm_button_text_translation {
      ...TranslationFragment
    }
    confirmation_cancel_button_text_translation {
      ...TranslationFragment
    }
    offer_coupon {
      id
      type
      amount_off
      duration
      duration_interval
      duration_count
      months
      apply_to
      override_active_coupon
      end_trial
      platform_id
      platform_coupon {
        code
      }
    }
    offer_change_plan {
      platform_plan_id
      prorate
      change_at
      reset_billing_date
      offer_coupon_id
      offer_coupon_offer {
        offer_coupon {
          id
          offer_id
          type
          amount_off
          duration
          duration_count
          duration_interval
          months
          platform_id
        }
      }
    }
    offer_modify_subscription {
      prorate
      modify_at
      offer_modify_subscription_options {
        type
        platform_connection_id
        platform_plan_id
        platform_addon_id
        custom_plan_id
      }
    }
    offer_custom {
      link_href
      link_text
    }
    offer_trial_extension {
      days
      allow_without_trial
    }
    offer_pause_subscription {
      pause_at
      offer_pause_subscription_options(order_by: { position: asc }) {
        type
        interval
        interval_count
        metadata
        text_translation {
          ...TranslationFragment
        }
      }
    }
    offer_upgrade {
      platform_plan_id
      change_at
      platform_plan {
        name
        platform_id
        platform_plan_group {
          name
          platform_id
          code
        }
      }
    }
    offer_downgrade {
      platform_plan_id
      change_at
      platform_plan {
        name
        platform_id
        platform_plan_group {
          name
          platform_id
          code
        }
      }
    }
    offer_product_swap {
      specific_products
      offer_product_swap_platform_ecommerce_products {
        platform_ecommerce_product_id
        platform_ecommerce_product {
          platform_id
          name
          image_url
          active
          platform_plan_ecommerce_products(
            where: { primary: { _eq: true } }
            limit: 1
          ) {
            discount_amount
            discount_type
          }
        }
        offer_product_swap_platform_ecommerce_product_platform_variants {
          platform_variant {
            platform_id
            name
            price
            currency
            platform_variant_options {
              key
              value
            }
          }
        }
      }
    }
    offer_reschedule_order {
      skip
      reschedule
      skip_button_text_translation {
        ...TranslationFragment
      }
      reschedule_button_text_translation {
        ...TranslationFragment
      }
    }
    account {
      platform_connection {
        platform
        import_completed_at
      }
    }
    offer_timer {
      offer_timer_mode
      start_time
      end_time
      duration
      show_timer
    }
    offer_tags {
      tag {
        id
        name
      }
    }
    offer_autopilot {
      strategy
    }
  }
  ${TranslationFragment}
`;
